<script>
  import { z } from 'zod';

  import TucfisPublicationConfig from '../../config/web-components/TucfisPublicationConfig.js';
  import { DataTransformer } from '../../utils/transformer.js';
  import { createParseAndValidateAttributesStore } from '../../utils/stores/parseAndValidateAttributesStore.js';

  import { getListData } from '../../services/accessControl.js';

  import Publication from './Publication.svelte';
  import CitationDialog from '../shared/citation/CitationDialog.svelte';
  import ShowError from '../ShowError.svelte';
  import Skeleton from '../shared/LoadingSkeletonSquare.svelte';
  import FilterBar from '../filterbar/FilterBar.svelte';

  export let orgUnit;
  export let sortBy;
  export let sort;
  export let limitTo;
  export let showFilterBar;

  const {
    attributes,
    state: attributesState,
    parseAndValidate: parseAttributes
  } = createParseAndValidateAttributesStore();

  $: parseAttributes({
    orgUnit: {
      variable: orgUnit,
      displayName: 'org-unit',
      validationSchema: z.string().min(1)
    },
    sortBy: {
      variable: sortBy,
      displayName: 'sort-by',
      validationSchema: z
        .enum(
          Object.keys(TucfisPublicationConfig.allowedSortByVariablesAttrToProp)
        )
        .optional()
        .transform(
          value =>
            TucfisPublicationConfig.allowedSortByVariablesAttrToProp[value]
        )
    }
  });

  let clientTransformer;

  $: {
    clientTransformer = new DataTransformer();

    switch ($attributes.sortBy) {
      case 'author':
        clientTransformer = clientTransformer.sortString(sort, item => {
          return item.author?.[0]?.family;
        });
        break;
      case 'issued':
        clientTransformer = clientTransformer.sortString(sort, item => {
          return item.issued?.['date-parts']?.[0]?.[0].toString();
        });
        break;
      default:
        clientTransformer = clientTransformer.sortString(sort, item => {
          return item[$attributes.sortBy];
        });
        break;
    }

    if (limitTo) {
      clientTransformer = clientTransformer.limitResults(limitTo);
    }
  }

  let citationDialog;
  const showCitationDialog = data => citationDialog.showCitation(data);

  const updateSort = value => {
    sort = value;
  };

  const updateSortBy = value => {
    sortBy = value;
  };

  const updateLimitTo = value => {
    limitTo = value;
  };
</script>

{#if $attributesState.isValid}
  {#await getListData('publications', { orgUnit })}
    <div class="list-container">
      {#each new Array(5).fill(true) as _}
        <hr />
        <Skeleton />
      {/each}
    </div>
  {:then data}
    <CitationDialog bind:this={citationDialog} />
    {#if showFilterBar}
      <FilterBar
        {updateSortBy}
        {updateSort}
        {sort}
        {sortBy}
        {limitTo}
        {updateLimitTo}
        showVariables={Object.keys(
          TucfisPublicationConfig.allowedSortByVariablesAttrToProp
        )}
      />
    {/if}
    <div class="list-container">
      {#each clientTransformer.transform(data) as publication}
        <hr />
        <Publication
          publication={publication.publication}
          title={publication.title}
          authors={publication.author}
          publisher={publication.publisher}
          DOI={publication.DOI}
          data={publication}
          {showCitationDialog}
        />
      {/each}
    </div>
  {:catch e}
    <ShowError message={'Error when loading data: ' + e.toString()} />
  {/await}
{:else}
  <ShowError message={$attributesState.errors} />
{/if}

<style lang="scss">
  .list-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    padding: 14px 0;
  }

  .list-container hr:first-child {
    display: none;
  }
</style>
