<svelte:options
  customElement={{
    tag: 'tucfis-project'
  }}
/>

<script>
  import { setContext } from 'svelte';

  import { getElementFromAC } from '../services/accessControl.js';

  import Project from '../components/project/Project.svelte';
  import ShowError from '../components/ShowError.svelte';
  import ProjectSkeleton from '../components/shared/LoadingSkeletonSquare.svelte';

  export let id =
    'https://tucid.tu-chemnitz.de/giim/individual/project-gepris-416228727';
  export let lang = 'en';
  setContext('language', lang);
</script>

{#await getElementFromAC('projects', id)}
  <ProjectSkeleton />
{:then fetchedData}
  <Project
    uri={fetchedData.id}
    title={fetchedData.name}
    startDate={fetchedData.intervalStart}
    endDate={fetchedData.intervalEnd}
    description={fetchedData.description}
    participants={fetchedData.participant}
    organizations={fetchedData.organizations}
    fieldsOfResearch={fetchedData.fieldsOfResearch}
    sustainableDevelopmentGoals={fetchedData.sustainableDevelopmentGoals}
    partners={fetchedData.partner}
    funder={fetchedData.funder}
    image={fetchedData.thumbnail}
  />
{:catch e}
  <ShowError message={e} />
{/await}
