import { writable } from 'svelte/store';
import { parseZodIssue } from '../parsers';

/**
 * Creates a parse and validate store that manages the state of attribute parsing and validation.
 *
 * @returns {Object} The parse and validate store with the following properties:
 * - attributes: A writable Svelte store that holds the parsed attributes. Initially, it's an empty object.
 * - state: A writable Svelte store that holds the state of the parsing and validation operation. It's an object with the following properties:
 * - parse: A function that parses and validates the attributes. It takes an attribute object as an argument.
 *
 * # parse
 * @param {Object} attr - The attribute object with the following properties:
 * - variable: The attribute variable to be parsed and validated.
 * - validationSchema: A ZOD schema used for validating the parsed attribute variable.
 * - displayName: A string that represents a human-readable name of the attribute.
 * - mandatory: A boolean indicating whether the attribute is mandatory or not.
 *
 */
export function createParseAndValidateAttributesStore() {
  const attributes = writable({});
  const state = writable({
    errors: [],
    hasErrors: false,
    isValid: false
  });

  function parseAndValidate(attr) {
    const newAttr = {};
    const errorMessages = [];

    Object.entries(attr).forEach(
      ([key, { variable, validationSchema, displayName }]) => {
        if (validationSchema) {
          const resultOfValidation = validationSchema.safeParse(variable);

          if (resultOfValidation.success) {
            newAttr[key] = resultOfValidation.data;
          } else {
            const errorMessage = parseZodIssue(
              resultOfValidation.error,
              displayName ?? key
            );
            errorMessages.push(errorMessage);
            console.error(errorMessage);
          }
        }
      }
    );

    attributes.set(newAttr);
    state.set({
      errors: errorMessages,
      hasErrors: errorMessages.length > 0,
      isValid: errorMessages.length === 0
    });
  }

  return {
    attributes,
    state,
    parseAndValidate
  };
}
