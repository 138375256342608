<script>
  import { getContext } from 'svelte';
  import i18n_dictionary from '../../config/i18n.js';
  import { parsePublicationAuthors } from '../../utils/parsers.js';

  import Cite from '../icons/Cite.svelte';
  import CiteButton from '../shared/citation/CiteButton.svelte';

  export let publication;
  export let title;
  export let authors;
  export let publisher;
  export let DOI;
  export let data;
  export let showCitationDialog;

  const i18n = key => {
    return i18n_dictionary[key][getContext('language')];
  };
</script>

<div id="bib-container">
  <!-- <CitationDialog bind:this={citationDialog} /> -->
  <div id="tucbib-container">
    <h3 id="tucbib-title"><a href={publication}>{title}</a></h3>
    <div id="tucbib-data-rows">
      <span id="tucbib-author-list">
        {parsePublicationAuthors(authors)}
      </span>
      <div>
        <b>{i18n('publisher')}: </b>{publisher}
      </div>
      {#if DOI && DOI.startsWith('https://doi.org/')}
        <div>
          <b>DOI: </b>
          <a href={DOI}>{DOI}</a>
        </div>
      {:else if DOI}
        <div>
          <b>DOI: </b>
          <a href="https://doi.org/{DOI}">{DOI}</a>
        </div>
      {/if}
    </div>
    <CiteButton onClick={() => showCitationDialog(data)} />
  </div>
</div>

<style lang="scss">
  #tucbib-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 9px;
  }

  #tucbib-title {
    margin: 0;
    padding: 0;
    margin-bottom: 4px;
  }

  #tucbib-author-list {
    white-space: pre;
    display: flex;
    flex-direction: row;
  }

  #tucbib-data-rows a,
  #tucbib-title a {
    text-decoration: none;
    color: var(--primary-color);
  }

  #tucbib-data-rows a:hover,
  #tucbib-title a:hover {
    text-decoration: underline;
  }

  #tucbib-data-rows {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
</style>
