<svelte:options
  customElement={{
    tag: 'tucfis-people'
  }}
/>

<script>
  import { setContext } from 'svelte';
  import { z } from 'zod';

  import { createParseAndValidateAttributesStore } from '../utils/stores/parseAndValidateAttributesStore.js';
  import {
    parseSeparatedStringIntoObject,
    parsePeopleAddress,
    parsePeopleRoom,
    parsePeoplePosition,
    parsePeopleResearchAreas
  } from '../utils/parsers.js';
  import { getElementFromAC } from '../services/accessControl.js';

  import People from '../components/people/People.svelte';
  import PeopleSkeleton from '../components/shared/LoadingSkeletonRound.svelte';
  import ShowError from '../components/ShowError.svelte';

  export let id;
  export let show = undefined;
  export let lang = 'en';
  export let context = undefined;

  setContext('language', lang);

  const allowedShowValues = ['positions', 'research-areas'];

  const {
    attributes,
    state: attributesState,
    parseAndValidate: parseAttributes
  } = createParseAndValidateAttributesStore();
  $: parseAttributes({
    show: {
      variable: show,
      validationSchema: z
        .string()
        .optional()
        .transform(parseSeparatedStringIntoObject)
        .refine(
          obj =>
            Object.keys(obj).every(key => {
              return allowedShowValues.some(allowedKey => allowedKey === key);
            }),
          {
            message: `Allowed keys are '${allowedShowValues.join("', '")}'`
          }
        )
    }
  });
</script>

{#if $attributesState.isValid}
  {#await getElementFromAC('people', id)}
    <PeopleSkeleton />
  {:then people}
    <People
      id={people.id}
      firstName={people.firstName}
      lastName={people.lastName}
      email={people.primaryEmail}
      position={$attributes.show?.['positions']
        ? parsePeoplePosition(people.positions, context)
        : undefined}
      address={parsePeopleAddress({
        streetAddress: people.streetAddress,
        postalCode: people.postalCode,
        locality: people.locality
      })}
      room={parsePeopleRoom({
        oldRoom: people.locatedInRoomOld,
        newRoom: people.locatedInRoom
      })}
      image={people.thumbnail}
      researchAreas={$attributes.show?.['research-areas']
        ? parsePeopleResearchAreas(people.researchAreas)
        : undefined}
    />
  {:catch error}
    <ShowError message={'Error when loading data: ' + error.toString()} />
  {/await}
{:else}
  <ShowError message={$attributesState.errors} />
{/if}
