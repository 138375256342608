<script>
  import Image from './Image.svelte';
  import i18n_dictionary from '../../config/i18n.js';
  import { getContext } from 'svelte';
  import LinkedIn from '../icons/LinkedIn.svelte';
  import X from '../icons/X.svelte';
  import Facebook from '../icons/Facebook.svelte';

  export let id;
  export let email;
  export let position = 'Professor';
  export let firstName;
  export let lastName;
  export let telephone;
  export let fax;
  export let address;
  export let room;
  export let researchAreas;
  export let image;
  export let socialMedia = {
    linkedIn: 'https://www.linkedin.com/',
    facebook: 'https://www.facebook.com/',
    xing: 'https://www.xing.com/'
  };
  export let title = '';
  export let motto;

  const i18n = key => {
    return i18n_dictionary[key][getContext('language')];
  };
</script>

<div class="people-vcard">
  <div class="header-container">
    <div class="header">
      <Image
        src={image}
        alt={`${i18n('portraitOf')} ${firstName} ${lastName}`}
        placeHolderAlt={`${i18n('placeholderFor')}: ${i18n(
          'portraitOf'
        )} ${firstName} ${lastName}`}
        reroute={{
          route: 'https://fis.tu-chemnitz.de/vivo/',
          rerouteTo:
            'https://vsr.informatik.tu-chemnitz.de/tucfisWebComponents/'
        }}
      />
    </div>
  </div>
  <div class="info">
    {#if id}
      <a href={id}>
        <h2 class="title">{`${title} ${firstName} ${lastName}`}</h2>
      </a>
    {:else}
      <h2 class="title">{`${title} ${firstName} ${lastName}`}</h2>
    {/if}
    {#if position}
      <h2 class="position">{position}</h2>
    {/if}

    {#if motto}
      <p class="motto">{motto}</p>
    {/if}
      <hr>
    <div class="info-footer">
        <p class="do-contact">Contact me via</p>
        {#if socialMedia}
        <div id="social-icons-container">
          <a href={socialMedia.linkedIn} target="_blank">
            <LinkedIn />
          </a>
          <a href={socialMedia.facebook} target="_blank">
            <Facebook />
          </a>
          <a href={socialMedia.xing} target="_blank">
            <X />
          </a>
          <p class="do-contact">or</p>
          <button class="vcard-export-btn" on:click={exportVCard}>Export as vCard</button>
        </div>
      {/if}
    </div>
  </div>
</div>

<style lang="scss">

.vcard-export-btn {
    background-color: var(--primary-color);
    color: white;
    border: none;
    margin-left: 1em;
    font-weight: bold;
    font-size: 1em;
    padding: 8px 12px;
    border-radius: 3px;
    cursor: pointer;
  }

  .vcard-export-btn:hover {
    background-color: var(--muted-primary-color);
  }

  .header-container {
    background-color: var(--primary-color);
    height: 100%;
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: stretch;
    border: 3.5px solid white;
    border-radius: 50%;
  }

  .people-vcard {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 100%;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  a {
    text-decoration: none;
  }

  .title {
    font-weight: bold;
    color: var(--primary-color);
    text-decoration: none;
    margin-bottom: 0.5em;
  }

  a:hover .title {
    text-decoration: underline;
  }

  .position {
    font-size: calc(var(--font-size) * 1.07);
    font-weight: normal;
    font-style: italic;
    margin: 0;
    color: #777;
    text-transform: uppercase;
  }

  .motto {
    font-style: italic;
    font-size: 1.5em;
    color: var(--muted-primary-color);
    text-align: center;
    padding: 0.5em;
    border-radius: 4px;
  }

  hr {
    width: 100%;
    border: 0;
    border-top: 1px solid #aaa;
  }

  .motto::before,
  .motto::after {
    content: '“'; /* Left quote */
    font-size: 1.5em; /* Larger quote size for emphasis */
    color: var(--primary-color);
  }

  .motto::after {
    content: '”'; /* Right quote */
  }

  #social-icons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    //margin: 12px 0;
    padding-top: 15px;
  }

  #social-icons-container > a {
    width: 35px;
    //margin: 0 8px;
    color: #777;
  }

  #social-icons-container > a:hover {
    color: var(--primary-color);
  }

  .info-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1em;
    color: #555;
    margin-top: 1em;
  }

  .do-contact {
    font-weight: bold;
    margin: 0;
  }

</style>