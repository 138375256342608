import { Cite, plugins } from '@citation-js/core';
// eslint-disable-next-line no-unused-vars
import pluginCsl from '@citation-js/plugin-csl';

export const initiateCitation = async () => {
  let response = await fetch(
    'https://raw.githubusercontent.com/citation-style-language/styles/master/ieee.csl'
  );
  let template = await response.text();
  let config = plugins.config.get('@csl');
  config.templates.add('ieee', template);

  const cite = (input, config) => {
    try {
      const citation = new Cite(input).format('bibliography', {
        format: config.format,
        template: config.template,
        lang: config.lang
      });
      return citation;
    } catch {
      return '';
    }
  };

  return { cite };
};
