<script>
  import { getContext } from 'svelte';
  import i18n_dictionary from '../../config/i18n.js';
  import Description from '../shared/Description.svelte';
  import Image from '../shared/Image.svelte';
  import TruncatedLinkList from './TruncatedLinkList.svelte';
  import moment from 'moment';

  export let uri;
  export let title;
  export let startDate;
  export let endDate;
  export let description;
  export let participants;
  export let organizations;
  export let fieldsOfResearch;
  export let sustainableDevelopmentGoals;
  export let partners;
  export let funder;
  export let image;

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };

  let progress = 0;
  let timeLeft = '';
  let borderRadius = '';
  let invalidProgress = '';

  $: if (startDate) {
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : null;
    const now = new Date();

    startDate = start.toLocaleDateString(undefined, options);
    if (end) {
      endDate = end.toLocaleDateString(undefined, options);
    }

    if (end) {
      const totalDuration = end - start;
      const elapsedDuration = now - start;
      const remainingDuration = end - now;

      if (elapsedDuration > 0 && totalDuration > 0) {
        progress = Math.min((elapsedDuration / totalDuration) * 100, 100);
      }

      if (remainingDuration > 0) {
        const daysLeft = Math.ceil(remainingDuration / (1000 * 60 * 60 * 24));  
        timeLeft = `${moment.duration(remainingDuration).humanize()} left`;
      } else {
        timeLeft = 'Completed';
        borderRadius = 'border-radius: 8px;';
      }
    } else {
      progress = 0;
      timeLeft = 'End date unknown';
      invalidProgress = 'visibility: hidden;';
    }
  }

  const i18n = key => {
    return i18n_dictionary[key][getContext('language')];
  };
</script>

<div id="main-container">
  <div id="text-container">
    <a href={uri} title={i18n('showProfilePageOf') + ' ' + title}>
      <h3>{title}</h3>
    </a>
    <div class="progress-bar-container">
      <p>
        {#if !(startDate || endDate)}
          <span></span>
        {:else}
          {#if startDate}
            <span class="date">{startDate}</span>
          {:else}
            <span><it>unknown start date</it></span>
          {/if}
          <span>-</span>
          {#if endDate}
            <span class="date">{endDate}</span>
          {:else}
            <span><it>unknown end date</it></span>
          {/if}
        {/if}
      </p>
      <div class="bar">
        <div class="progress-bar" style="width: {progress}%; {borderRadius} {invalidProgress}"></div>
        <div class="remaining-time-bar" style="width: {100-progress}%; {borderRadius} {invalidProgress}"></div>
      </div>
      <p class="time-left">{timeLeft}</p>
    </div>
    <Description {description} />
    {#if participants}
      <div id="participants">
        <span>{i18n('projectHeads')}</span>
        <ul>
          {#each participants as participant}
            <li>
              <a
                href={participant.id}
                title={i18n('showProfilePageOf') + ' ' + participant.label}
              >
                {participant.label}
              </a>
              {#if participant.type}<span>{participant.type}</span>{/if}
            </li>
          {/each}
        </ul>
      </div>
    {/if}
    {#if partners}
      <div id="partners">
        <span>{i18n('partners')}</span>
        <TruncatedLinkList list={partners} showOnlyFirstN="3" />
      </div>
    {/if}
    {#if funder}
      <p id="funder">
        <span>
          {i18n('funder')}:
        </span>
        <span>
          {funder}
        </span>
      </p>
    {/if}
  </div>
  <div id="image-container">
    <Image
      src={image}
      showOnError={false}
      alt={i18n('iconOfProject')}
      placeHolderAlt={`${i18n('placeholderFor')}: ${i18n('iconOfProject')}`}
      reroute={{
        route: 'https://fis.tu-chemnitz.de/vivo/',
        rerouteTo: 'https://vsr.informatik.tu-chemnitz.de/tucfisWebComponents/'
      }}
    />
  </div>
</div>

<style lang="scss">
  #image-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 0.2;
  }

  /* button as link style that underlines on hover */
  button {
    background: none;
    border: none;
    padding: 4px;
    font: inherit;
    cursor: pointer;
    color: var(--primary-color);
    text-decoration: underline;
    margin: 0;
    font-weight: 600;
  }

  button:hover {
    color: black;
  }

  button:active {
    color: black;
  }

  p {
    margin: 0;
    padding: 0;
  }

  #main-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    gap: 10px;
    padding: 5px;
    //border: 1px solid #000000;
  }

  #text-container {
    flex: 0.8;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 0 4px;
    gap: 20px;
    //border: 1px solid #000000;
  }

  #text-container h3 {
    font-size: calc(var(--font-size) * 1.1);
    font-weight: bold;
    margin: 0;
    color: var(--primary-color);
  }

  .date {
    font-weight: bold;
    margin: 0;
  }

  .progress-bar-container {
    width: 55%;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    border-radius: 8px;
    overflow: hidden;
    padding: 10px;
    gap: 10px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .progress-bar {
    height: 8px;
    background-color: var(--primary-color);
    border-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: width 0.3s;
  }

  .bar {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
  }

  .remaining-time-bar {
    height: 8px;
    background-color: var(--extremely-muted-primary-color);
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    transition: width 0.3s;
  }

  .time-left {
    font-size: 0.9em;
    color: #555;
  }

  #text-container a {
    color: var(--primary-color);
    text-decoration: none;
  }

  #text-container a:hover {
    text-decoration: underline;
  }

  #partners,
  #participants {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  #partners > span,
  #participants > span {
    font-weight: bold;
    margin: 0;
  }

  #partners ul,
  #participants ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 5px;
    //border: 1px solid #000000;
  }

  #partners li,
  #participants li {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-wrap: wrap;
    gap: 3px;
  }

  #partners li > a,
  #participants li > a {
    margin: 0;
    font-weight: 500;
  }

  #partners li > span,
  #participants li > span {
    margin: 0;
    font-weight: bold;
    color: #777;
  }

  #funder {
    margin: 0;
  }

  #funder :first-child {
    font-weight: bold;
  }
</style>