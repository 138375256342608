<script>
  export let style = undefined;
</script>

<svg
  {style}
  viewBox="0 0 48 48"
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
    id="SVGRepo_tracerCarrier"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></g><g id="SVGRepo_iconCarrier">
    <g id="Layer_2" data-name="Layer 2">
      <g id="invisible_box" data-name="invisible box">
        <rect width="48" height="48" fill="none"></rect>
      </g>
      <g id="Icons">
        <g>
          <rect x="33" y="4" width="10" height="40" rx="2"></rect>
          <rect x="19" y="19" width="10" height="25" rx="2"></rect>
          <rect x="5" y="33" width="10" height="11" rx="2"></rect>
        </g>
      </g>
    </g>
  </g></svg
>
