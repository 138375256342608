<svelte:options
  customElement={{
    tag: 'tucfis-dataset'
  }}
/>

<script>
  import { setContext } from 'svelte';
  import { getDatasetFromCKAN } from '../services/accessControl';
  import ShowError from '../components/ShowError.svelte';
  import Dataset from '../components/dataset/Dataset.svelte';
  import Skeleton from '../components/shared/LoadingSkeletonRound.svelte';
  import CitationDialog from '../components/shared/citation/CitationDialog.svelte';

  export let id;
  export let lang = 'en';

  setContext('language', lang);

  let citationDialog;

  const showCitationDialog = data => citationDialog.showCitation(data);
</script>

{#await getDatasetFromCKAN(id)}
  <Skeleton />
{:then dataset}
  <div>
    <CitationDialog bind:this={citationDialog} />
    <Dataset
      title={dataset.title}
      authors={dataset.creators}
      publisher={dataset.publisher}
      publicationYear={dataset.publication_year}
      resourceType={dataset.resource_type}
      description={dataset.description}
      tags={dataset.tags}
      license={{
        title: dataset.license_title,
        url: dataset.license_url
      }}
      resources={dataset.resources}
      {showCitationDialog}
      rawData={dataset}
    />
  </div>
{:catch error}
  <ShowError message={'Error when loading data: ' + error.toString()} />
{/await}
