<script>
  import i18n_dictionary from '../../config/i18n.js';
  import { getContext } from 'svelte';

  export let list = [];
  export let showOnlyFirstN = Number.MAX_VALUE;

  let currentShowOnlyFirstN = showOnlyFirstN;

  function sliceUntil(l, to) {
    return l.slice(0, to);
  }

  const i18n = key => {
    return i18n_dictionary[key][getContext('language')];
  };

  let listChanged = false;

  const getButtonLabel = () => {
    if (currentShowOnlyFirstN < list.length) {
      return i18n('more');
    } else return i18n('less');
  };

  const getDots = () => {
    if (currentShowOnlyFirstN < list.length) {
      return '...';
    }
    return '';
  };

  const showButton = () => {
    return list.length > showOnlyFirstN;
  };
</script>

{#key listChanged}
  <div id="main-container">
    <ul>
      {#each sliceUntil(list, currentShowOnlyFirstN) as partner}
        <li class="badge">
          <a
            href={partner.id}
            title={i18n('showProfilePageOf') + ' ' + partner.label}
          >
            {partner.label}
          </a>
        </li>
      {/each}
    </ul>
    {#if showButton()}
      <span class="dots">{getDots()}</span>
      <button
        on:click={() => {
          if (currentShowOnlyFirstN == showOnlyFirstN) {
            currentShowOnlyFirstN = Number.MAX_VALUE;
          } else {
            currentShowOnlyFirstN = showOnlyFirstN;
          }
          listChanged = !listChanged;
        }}>{getButtonLabel()}</button
      >
    {/if}
  </div>
{/key}

<style lang="scss">
  #main-container {
    display: flex;
    flex-direction: column;
  }

  .dots {
    font-weight: bolder;
  }

  /* button as link style that underlines on hover */
  button {
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;
    color: var(--primary-color);
    text-decoration: underline;
    margin: 0;
    padding: 0;
    padding-top: 5px;
    font-weight: 600;
    align-self: flex-start;
    font-style: italic;
  }

  button:hover {
    color: black;
  }

  button:active {
    color: black;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 10px;
  }

  li.badge {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border-radius: 20px;
  }

  a {
    font-weight: 500;
    color: #242424;
    text-decoration: none;
  }

  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
</style>
