export class DataTransformer {
  constructor() {
    this.transformFunctions = [];
  }

  /**
   * Applies all transformation functions to the data.
   * @param {Array} data - The data to transform.
   * @param {Object} params - The transformation parameters.
   * @returns {Array} The transformed data.
   */
  transform(data, params) {
    const transformResult = this.transformFunctions.reduce(
      (result, fn) => fn(result, params),
      data
    );
    return transformResult;
  }

  customTransformation(transformFn) {
    this.transformFunctions.push(transformFn);
    return this;
  }

  /**
   * Sorts the data based on string values.
   * @param {boolean} ascending - Whether to sort in ascending order.
   * @param {Function} valueExtractor - The function to extract the string value to sort by.
   * @returns {DataTransformer} The data transformer instance.
   */
  sortString(sortDirection, valueExtractor = x => x) {
    let sorter = data => {
      if (!data || data.length === 0) {
        return [];
      }

      return [...data].sort((a, b) => {
        const aValue = valueExtractor(a);
        const bValue = valueExtractor(b);

        if (aValue && !bValue) {
          return -1;
        }

        if (!aValue && bValue) {
          return 1;
        }

        if (
          !aValue ||
          !bValue ||
          typeof aValue !== 'string' ||
          typeof bValue !== 'string'
        ) {
          return 1;
        }

        return (
          aValue.localeCompare(bValue) *
          (sortDirection === 'ascending' ? 1 : -1)
        );
      });
    };

    this.transformFunctions.push(sorter);

    return this;
  }

  /**
   * Sorts the data based on date values.
   * @param {string} sortDirection - "ascending" or "descending".
   * @param {Function} valueExtractor - The function to extract the string value to sort by.
   * @returns {DataTransformer} The data transformer instance.
   */
  sortDate(sortDirection, valueExtractor = x => x) {
    let sorter = data => {
      if (!data || data.length === 0) {
        return [];
      }

      const result = [...data].sort((a, b) => {
        return (
          (Date.parse(valueExtractor(a)) - Date.parse(valueExtractor(b))) *
          (sortDirection === 'ascending' ? 1 : -1)
        );
      });

      return result;
    };

    this.transformFunctions.push(sorter);

    return this;
  }

  /**
   * Sorts the data based on the rank of string values.
   * @param {boolean} ascending - Whether to sort in ascending order.
   * @param {Function} valueExtractor - The function to extract the string value to sort by.
   * @param {Object} ranks - The object mapping string values to ranks.
   * @returns {DataTransformer} The data transformer instance.
   */
  sortStringByRank(sortDirection, valueExtractor = x => x, ranks = {}) {
    let sorter = data => {
      return [...data].sort((a, b) => {
        let aValue = valueExtractor(a);
        let bValue = valueExtractor(b);

        let aRank =
          aValue.toLowerCase() in ranks ? ranks[aValue.toLowerCase()] : 99;
        let bRank =
          bValue.toLowerCase() in ranks ? ranks[bValue.toLowerCase()] : 99;

        let rankDifference =
          (aRank - bRank) * (sortDirection === 'ascending' ? 1 : -1);

        // If ranks are equal, sort by name
        if (rankDifference === 0 && a.name && b.name) {
          return (
            a.name.localeCompare(b.name) *
            (sortDirection === 'ascending' ? 1 : -1)
          );
        }

        return rankDifference;
      });
    };

    this.transformFunctions.push(sorter);

    return this;
  }

  /**
   * Filters the data based on a certain condition.
   * @param {Function} valueExtractor - The function to extract the value to match against.
   * @returns {DataTransformer} The data transformer instance.
   */
  filter(valueExtractor) {
    if (typeof valueExtractor !== 'function') {
      throw new Error('valueExtractor must be a function');
    }

    let filterer = data => data.filter(item => valueExtractor(item));
    this.transformFunctions.push(filterer);
    return this;
  }

  /**
   * Limits the results to the specified number of items.
   * @param {number} n - The maximum number of items to include.
   * @returns {DataTransformer} The data transformer instance.
   */
  limitResults(n) {
    if (n && (typeof n !== 'number' || n <= 0 || Math.floor(n) !== n)) {
      throw new Error('n must be a positive integer');
    }

    let limiter = data => data.slice(0, n);
    this.transformFunctions.push(limiter);
    return this;
  }

  /**
   * Finds the first item that matches a certain condition.
   * @param {Function} valueExtractor - The function to extract the value to match against.
   * @param {*} targetValue - The value to match against.
   * @returns {DataTransformer} The data transformer instance.
   */
  findFirstMatch(valueExtractor, targetValue) {
    if (typeof matchFunction !== 'function') {
      throw new Error('matchFunction must be a function');
    }

    let finder = data => {
      let match = data.find(x => valueExtractor(x) === targetValue);
      if (match === undefined) {
        throw new Error('No match found');
      }
      return match;
    };

    this.transformFunctions.push(finder);
    return this;
  }
}
