const attrToPropMap = {
  id: 'id', // Contains string
  description: 'description', // Contains string
  title: 'title' // Contains string
};

const TucfisServiceConfig = {
  tag: 'tucfis-service',
  allowedSortByVariablesAttrToProp: attrToPropMap,
  allowedFilterByVariablesAttrToProp: attrToPropMap
};

export default TucfisServiceConfig;
