<script>

</script>

<div class="esf-logo">
    <img src="https://vsr.informatik.tu-chemnitz.de/svnproxy/images/ESF_EU_hoch_2014-2020_rgb.jpg" alt="ESF Sponsor Logo"/>
</div>

<style>
  .esf-logo img{
    max-width:100%;
    max-height:100%;
    margin-left: -65px;
  }
  .esf-logo {
    height:200px;
    width: 200px;
  }
</style>

