<script>
  import { z } from 'zod';

  import TucfisProjectConfig from '../../config/web-components/TucfisProjectConfig.js';

  import { DataTransformer } from '../../utils/transformer.js';

  import { createParseAndValidateAttributesStore } from '../../utils/stores/parseAndValidateAttributesStore.js';

  import { getListData } from '../../services/accessControl.js';

  import Project from './Project.svelte';
  import ShowError from '../../components/ShowError.svelte';
  import Skeleton from '../shared/LoadingSkeletonSquare.svelte';
  import FilterBar from '../filterbar/FilterBar.svelte';

  export let orgUnit;
  export let sortBy;
  export let sort; // ascending | descending
  export let limitTo;
  export let filterValue;
  export let filterBy;
  export let filterOperation;
  export let showFilterBar;

  const {
    attributes,
    state: attributesState,
    parseAndValidate: parseAttributes
  } = createParseAndValidateAttributesStore();

  $: parseAttributes({
    orgUnit: {
      variable: orgUnit,
      displayName: 'org-unit',
      validationSchema: z.string().min(1)
    },
    sortBy: {
      variable: sortBy,
      displayName: 'sort-by',
      validationSchema: z
        .enum(Object.keys(TucfisProjectConfig.allowedSortByVariablesAttrToProp))
        .optional()
        .transform(
          value => TucfisProjectConfig.allowedSortByVariablesAttrToProp[value]
        )
    },
    filterBy: {
      variable: filterBy,
      displayName: 'filter-by',
      validationSchema: z
        .enum(
          Object.keys(TucfisProjectConfig.allowedFilterByVariablesAttrToProp)
        )
        .optional()
        .transform(
          value => TucfisProjectConfig.allowedFilterByVariablesAttrToProp[value]
        )
    }
  });

  let clientTransformer;

  $: {
    clientTransformer = new DataTransformer();

    switch ($attributes.sortBy) {
      case 'participant':
      case 'partner':
        clientTransformer = clientTransformer.sortString(sort, item => {
          return item[$attributes.sortBy]?.[0]?.label;
        });
        break;
      case 'organizations':
        clientTransformer = clientTransformer.sortString(sort, item => {
          return item.organizations?.[0];
        });
        break;
      case 'intervalStart':
      case 'intervalEnd':
        clientTransformer = clientTransformer.sortDate(
          sort,
          item => item[$attributes.sortBy]
        );
        break;
      default:
        clientTransformer = clientTransformer.sortString(sort, item => {
          return item[$attributes.sortBy];
        });
        break;
    }

    if (limitTo) {
      clientTransformer = clientTransformer.limitResults(limitTo);
    }
  }

  const updateSort = value => {
    sort = value;
  };

  const updateSortBy = value => {
    sortBy = value;
  };

  const updateLimitTo = value => {
    limitTo = value;
  };
</script>

{#if $attributesState.isValid}
  {#await getListData( 'projects', { orgUnit, filterConfig: { filterBy: $attributes.filterBy, filterValue, filterOperation } } )}
    <div class="list-container">
      {#each new Array(5).fill(true) as _}
        <hr />
        <Skeleton />
      {/each}
    </div>
  {:then data}
    {#if showFilterBar}
      <FilterBar
        {updateSortBy}
        {updateSort}
        {sort}
        {sortBy}
        {limitTo}
        {updateLimitTo}
        showVariables={Object.keys(
          TucfisProjectConfig.allowedSortByVariablesAttrToProp
        )}
      />
    {/if}
    <div class="list-container">
      {#each clientTransformer.transform(data) as project}
        <hr />
        <Project
          uri={project.id}
          title={project.name}
          startDate={project.intervalStart}
          endDate={project.intervalEnd}
          description={project.description}
          participants={project.participant}
          organizations={project.organizations}
          fieldsOfResearch={project.fieldsOfResearch}
          sustainableDevelopmentGoals={project.sustainableDevelopmentGoals}
          partners={project.partner}
          funder={project.funder}
          image={project.thumbnail}
        />
      {/each}
    </div>
  {:catch e}
    <ShowError message={'Error when loading data: ' + e.toString()} />
  {/await}
{:else}
  <ShowError message={$attributesState.errors} />
{/if}

<style lang="scss">
  .list-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    padding: 14px 0;
  }

  .list-container hr:first-child {
    display: none;
  }
</style>
