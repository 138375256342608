<script>
  export let limit;
  export let updateLimit;
  let active = false;
  let rememberedLimit;
</script>

<div id="main-container">
  <p>Limit to</p>
  <div>
    <input
      type="checkbox"
      id="active-limit"
      name="active-limit"
      checked={active}
      on:change={e => {
        const newChecked = e.target.checked;
        if (newChecked && limit === undefined) {
          const initialValue = 10;
          updateLimit(initialValue);
          rememberedLimit = initialValue;
        } else if (newChecked && limit === null) {
          updateLimit(rememberedLimit);
        } else {
          //rememberedLimit = limit;
          updateLimit(null);
        }
        active = newChecked;
      }}
    />
    <input
      type="range"
      min="1"
      max="20"
      value={rememberedLimit}
      on:input={e => {
        const valueTemp = parseInt(e.target.value);
        updateLimit(valueTemp);
        rememberedLimit = valueTemp;
      }}
      disabled={!active}
    />
    <input
      type="number"
      min="1"
      max="200"
      value={rememberedLimit}
      on:input={e => {
        const valueTemp = parseInt(e.target.value);
        updateLimit(valueTemp);
        rememberedLimit = valueTemp;
      }}
      disabled={!active}
    />
  </div>
</div>

<style lang="scss">
  #main-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 6px;
  }

  p {
    margin: 0;
    font-weight: bold;
    margin-left: 2px;
  }

  #main-container > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  input[type='range'] {
    width: 100px;
    accent-color: var(--primary-color);
  }

  input[type='number'] {
    height: 20px;
    width: 40px;
  }

  input[type='checkbox'] {
    width: 16px;
    height: 16px;
    accent-color: var(--primary-color);
  }

  /* input[type='checkbox']:checked {
    background-color: var(--primary-color);
  } */

  /* input[type='checkbox']:hover {
    background-color: var(--muted-primary-color);
  }

  input[type='checkbox']:disabled {
    background-color: #777;
  } */
</style>
