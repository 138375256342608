<script>
  import { beforeUpdate, getContext } from 'svelte';

  export let src;
  export let alt;
  export let placeHolderAlt = undefined;
  export let reroute = undefined;
  export let showOnError = true;
  let error = false;

  function handleError() {
    error = true;
  }

  const i18n = key => {
    return i18n_dictionary[key][getContext('language')];
  };

  beforeUpdate(() => {
    if (reroute) {
      const { route, rerouteTo } = reroute;
      // FIX: reroute images located on fis via vsr
      if (src?.startsWith(route)) {
        src = src.replace(route, rerouteTo);
      }
    }
  });
</script>

{#if src && !error}
  <div id="profile-picture">
    <img {src} {alt} on:error={handleError} />
  </div>
{:else if showOnError}
  <div id="profile-picture">
    <img
      src="https://vsr.informatik.tu-chemnitz.de/tucfis-web-components/v1/images/placeholder-project.jpg"
      alt={placeHolderAlt ?? 'Not available'}
    />
  </div>
{:else}
  {''}
{/if}

<style lang="scss">
  #profile-picture {
    width: 65%;
    max-width: 150px;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    //border: 1px solid #e0e0e0;
  }
  
  #profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
</style>
