<script>
  export let style = undefined;
</script>

<svg
  viewBox="0 0 32 32"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
  fill="currentColor"
  {style}
  ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
    id="SVGRepo_tracerCarrier"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></g><g id="SVGRepo_iconCarrier">
    <title>chevron-up-circle</title> <desc>Created with Sketch Beta.</desc>
    <defs> </defs>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      sketch:type="MSPage"
    >
      <g
        id="Icon-Set-Filled"
        sketch:type="MSLayerGroup"
        transform="translate(-154.000000, -1089.000000)"
        fill="currentColor"
      >
        <path
          d="M178.536,1109.54 C178.145,1109.93 177.512,1109.93 177.121,1109.54 L170,1102.41 L162.879,1109.54 C162.488,1109.93 161.855,1109.93 161.464,1109.54 C161.074,1109.15 161.074,1108.51 161.464,1108.12 L169.121,1100.46 C169.361,1100.22 169.689,1100.15 170,1100.21 C170.311,1100.15 170.639,1100.22 170.879,1100.46 L178.536,1108.12 C178.926,1108.51 178.926,1109.15 178.536,1109.54 L178.536,1109.54 Z M170,1089 C161.164,1089 154,1096.16 154,1105 C154,1113.84 161.164,1121 170,1121 C178.836,1121 186,1113.84 186,1105 C186,1096.16 178.836,1089 170,1089 L170,1089 Z"
          id="chevron-up-circle"
          sketch:type="MSShapeGroup"
        >
        </path>
      </g>
    </g>
  </g></svg
>
