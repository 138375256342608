const attrToPropMap = {
  src: 'thumbnail', // Contains string
  title: 'name', // Contains string
  description: 'description', // Contains string
  'start-date': 'intervalStart', // Contains string
  'end-date': 'intervalEnd', // Contains string
  funder: 'funder', // Contains string
  'funding-amount': 'fundingAmount', // Contains string
  participant: 'participant', // Contains string
  'sustainable-development-goals': 'sustainableDevelopmentGoals', // Contains string
  partner: 'partner', // Contains string
  'fields-of-research': 'fieldsOfResearch', // Contains string
  organizations: 'organizations' // Contains string
};

const TucfisProjectConfig = {
  tag: 'tucfis-project',
  allowedSortByVariablesAttrToProp: attrToPropMap,
  allowedFilterByVariablesAttrToProp: attrToPropMap
};

export default TucfisProjectConfig;
