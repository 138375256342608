<svg
  viewBox="0 0 20 20"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"
  ></g>
  <g id="SVGRepo_iconCarrier" fill="none" fill-rule="evenodd">
    <text
      x="0"
      y="20"
      font-family="Arial"
      font-size="13"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="1"
    >
      R
    </text>
    <text
      x="10"
      y="20"
      font-family="Arial"
      font-size="13"
      fill="#8DC63F"
      stroke="#8DC63F"
      stroke-width="1"
    >
      G
    </text>
  </g>
</svg>
