<script>
  import { slide } from 'svelte/transition';

  import SortComponent from './SortComponent.svelte';
  import LimitComponent from './LimitComponent.svelte';
  import ChevronDown from '../icons/ChevronDown.svelte';
  import ChevronUp from '../icons/ChevronUp.svelte';

  export let updateSortBy;
  export let updateSort;
  export let sort;
  export let sortBy;
  export let limitTo;
  export let updateLimitTo;
  export let showVariables;

  let showFilters = false;
</script>

<div id="main-container">
  <div id="toggle-container">
    <button on:click={() => (showFilters = !showFilters)}>
      <span class="icon"
        >{#if showFilters}
          <ChevronUp />
        {:else}
          <ChevronDown />
        {/if}
      </span>
      <span id="toggle-container-title">Toolbar</span>
    </button>
  </div>

  {#if showFilters}
    <div id="filter-bar" transition:slide>
      <SortComponent
        sortByOptions={showVariables}
        {updateSortBy}
        {updateSort}
        {sort}
        {sortBy}
      />
      <hr />
      <LimitComponent limit={limitTo} updateLimit={updateLimitTo} />
    </div>
  {/if}
</div>

<style lang="scss">
  #main-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }

  #toggle-container {
    background-color: #f5f5f5;
    padding: 10px 6px;
    border-bottom: 1px solid #ccc;
  }
  
  #toggle-container-title {
    font-size: calc(var(--font-size) * 1.1);
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 7px;
    //text-transform: uppercase;
  }

  button:hover {
    color: var(--primary-color);
  }

  .icon {
    width: 20px;
    height: 20px;
    color: var(--primary-color);
  }

  #filter-bar {
    display: flex;
    align-items: center;
    justify-content: start;
    align-self: stretch;
    gap: 14px;
    height: 70px;
    padding: 8px 10px;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
  }

  hr {
    width: 1px;
    height: 80%;
    margin: 0;
    border: none;
    background-color: #ccc;
  }
</style>
