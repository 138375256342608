<script>
  // biome-ignore lint/style/useConst: <explanation>
  export let title = '';
</script>

<main class="main-container">
  <div>
    <h1>{title}</h1>
    <hr />
    <slot></slot>
  </div>
</main>

<style lang="scss">
  .main-container {
    margin: 60px 160px;
  }

  h1 {
    font-size: 2.3rem;
    margin: 0;
    /* font-weight: bold; */
  }

  hr {
    margin: 30px 0;
  }

  @media (max-width: 1000px) {
    .main-container {
      margin: 40px 20px;
    }
  }
</style>