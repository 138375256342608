<script>
  import { getContext } from 'svelte';
  import i18n_dictionary from '../../config/i18n.js';
  import Description from '../shared/Description.svelte';
  import Image from '../shared/Image.svelte';

  export let uri;
  export let title;
  export let startDate;
  export let endDate;
  export let description;
  export let speaker;
  export let info;

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };

  let domParser = new DOMParser();

  $: startDateParsed = new Date(startDate).toLocaleDateString(
    undefined,
    options
  );
  $: endDateParsed = new Date(endDate).toLocaleDateString(undefined, options);

  let parseAttrs = text => {
    //https://stackoverflow.com/questions/2878703/split-string-once-in-javascript
    const [key, ...values] = text.split(':');
    const value = values.join(':');
    let pair = [key, value];
    if (pair.length == 1) return text;
    return pair[1].trim();
  };

  const i18n = key => {
    return i18n_dictionary[key][getContext('language')];
  };
</script>

<div class="main-container">
  <!-- <Image
      src={image}
      alt={i18n('iconOfProject')}
      placeHolderAlt={`${i18n('placeholderFor')}: ${i18n('iconOfProject')}`}
      reroute={{
        route: 'https://fis.tu-chemnitz.de/vivo/',
        rerouteTo: 'https://vsr.informatik.tu-chemnitz.de/tucfisWebComponents/'
      }}
    /> -->
  <div id="text-container">
    <a href={uri} title={i18n('showProfilePageOf') + ' ' + title}>
      <h3>{title}</h3>
    </a>
    <p>
      {#if !(startDate || endDate)}
        <span></span>
      {:else}
        {#if startDate}
          <span class="date">{startDateParsed}</span>
        {:else}
          <span><it>unknown start date</it></span>
        {/if}
        <span>-</span>
        {#if endDate}
          <span class="date">{endDateParsed}</span>
        {:else}
          <span><it>unknown end date</it></span>
        {/if}
      {/if}
    </p>
    <Description {description} />
    {#if speaker}
      <div id="speaker">
        <b>{i18n('speaker')}:</b>
        {parseAttrs(
          domParser.parseFromString(speaker, 'text/html').body.firstChild
            .textContent
        )}
      </div>
    {/if}
    {#if info}
      <div id="info">
        <b>Info:</b>
        {parseAttrs(
          domParser.parseFromString(info, 'text/html').body.firstChild
            .textContent
        )}
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  p {
    margin: 0;
    padding: 0;
  }

  .main-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 10px;
  }

  #text-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 0 4px;
    gap: 20px;
  }

  #text-container h3 {
    font-size: calc(var(--font-size) * 1.05);
    font-weight: bold;
    margin: 0;
    color: var(--primary-color);
  }

  .date {
    font-weight: bold;
    margin: 0;
  }

  #text-container a {
    color: var(--primary-color);
    text-decoration: none;
  }

  #text-container a:hover {
    text-decoration: underline;
  }
</style>
