export default {
  NAMESPACES: {
    TUCFIS_INDIVIDUAL: 'https://tucid.tu-chemnitz.de/giim/individual/'
  },
  ENDPOINTS: {
    //FIS_AC: 'http://localhost:8000/ac/',
    FIS_AC: 'https://vsr.informatik.tu-chemnitz.de/tucfis-shield/ac/v1/',
    TRIPLESTORE: 'https://triplestore1.informatik.tu-chemnitz.de/'
  }
};
