<script>
  export let message;
</script>

<div id="error-message">
  <h1>Error</h1>
  <!-- <p>{message}</p> -->
  <div>
    {#if Array.isArray(message)}
      {#each message as msg, index (index)}
        {#if true}
          <hr />
        {/if}
        <p>{msg}</p>
      {/each}
    {:else}
      <p>{message}</p>
    {/if}
  </div>
</div>

<style lang="scss">
  #error-message {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    height: 100px;
    //width: 100%;
    //flex-grow: 0;
    padding: 20px;
    gap: 8px;
  }

  #error-message div {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }

  #error-message hr {
    width: 100%;
    height: 1px;
    margin: 0 auto;
    border: none;
    background-color: #777;
  }

  h1 {
    font-size: calc(var(--font-size) * 1.6);
    margin: 0;
    color: #ff0000;
  }

  p {
    font-size: calc(var(--font-size) * 1.3);
    margin: 0;
  }
</style>
