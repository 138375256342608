<script>
  import Ascending from '../icons/Ascending.svelte';
  import Descending from '../icons/Descending.svelte';
  export let sortByOptions;
  export let updateSortBy;
  export let updateSort;
  export let sort;
  export let sortBy;
</script>

<div id="main-container">
  <p>Sort by</p>
  <div id="actions">
    <select
      id="sortBy"
      on:change={e => {
        if (e.target.value?.length === 0) {
          updateSortBy(undefined);
          return;
        }
        updateSortBy(e.target.value);
      }}
      value={sortBy}
    >
      <option value={undefined}>No sort</option>
      {#each sortByOptions as varName}
        <option value={varName}>{varName}</option>
      {/each}
    </select>
    <button
      disabled={!sortBy}
      on:click={() => {
        updateSort(sort === 'ascending' ? 'descending' : 'ascending');
      }}
    >
      {#if sort === 'ascending'}
        <Ascending />
      {:else}
        <Descending />
      {/if}
    </button>
  </div>
</div>

<style lang="scss">
  #main-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 6px;
  }

  p {
    margin: 0;
    font-weight: bold;
    margin-left: 2px;
  }

  #actions {
    display: flex;
    align-items: center;
    //flex-flow: row-reverse;
    gap: 8px;
  }

  button {
    background-color: var(--primary-color);
    color: white;
    width: 28px;
    height: 28px;
    padding: 3px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  button:disabled {
    background-color: #777;
    color: white;
    cursor: not-allowed;
  }

  button:hover:not(:disabled) {
    background-color: var(--muted-primary-color);
  }

  select {
    padding: 5px;
    border-radius: 5px;
  }
</style>
