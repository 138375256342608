const attrToPropMap = {
  author: 'author', // Contains string
  issued: 'issued', // Contains string
  publisher: 'publisher', // Contains string
  doi: 'DOI', // Contains string
  issn: 'issn', // Contains string
  'parent-publication-title': 'container-title', // Contains string
  'publication-type': 'type' // Contains string
};

const TucfisPublicationConfig = {
  tag: 'tucfis-publication',
  allowedSortByVariablesAttrToProp: attrToPropMap
};

export default TucfisPublicationConfig;
