<script>
  import { beforeUpdate, getContext } from "svelte";

  export let src;
  export let alt;
  export let placeHolderAlt = undefined;
  export let reroute = undefined;
  let error = false;

  function handleError() {
    error = true;
  }

  const i18n = (key) => {
    return i18n_dictionary[key][getContext("language")];
  };

  beforeUpdate(() => {
    if (reroute) {
      const { route, rerouteTo } = reroute;
      // FIX: reroute images located on fis via vsr
      if (src?.startsWith(route)) {
        src = src.replace(
          route,
          rerouteTo
        );
      }
    }
  });
</script>

<div id="profile-picture">
  {#if src && !error}
    <img {src} {alt} on:error={handleError} />
  {:else}
    <img
      src="https://vsr.informatik.tu-chemnitz.de/tucfis-web-components/v1/images/placeholder-portrait.jpg"
      alt={placeHolderAlt ?? "User's profile not available"}
    />
  {/if}
</div>

<style lang="scss">
  #profile-picture {
    /* width: 200px;
    height: 200px; */
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    /*align-self: flex-start;*/
  }

  #profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  #profile-picture {
    width: 185px;
    max-width: 185px;
    height: auto;

    @media (max-width: 768px) {
      max-width: 140px;
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }
</style>
