/**
 * Parses the main website of a person.
 *
 * @param {Object} config
 * @param {Object[]} config.websites - The websites object.
 * @param {Object} config.customUrls - The custom URLs object.
 * @param {string} config.originalUrl - The original URL.
 *
 * @returns {string} The parsed main website URL.
 */
export const parsePeopleMainWebsite = ({
  websites,
  customUrls,
  originalUrl
}) => {
  if (customUrls?.[originalUrl]) {
    return customUrls[originalUrl];
  }

  let websitesFiltered = websites?.filter(url => url.type == "Homepage");
  if (websitesFiltered?.length) {
    websitesFiltered.sort((a, b) => a.rank - b.rank);
    return websitesFiltered[0].url;
  }
  return originalUrl;
};

export const parseSocialMedia = (websites) => {
  let socialMedia = {};
  // TODO: Do a proper domain check here.
  websites?.forEach(website => {
     website = website.url;
    if (website.includes('twitter.com') || websites.includes("x.com")) {
      socialMedia['twitter'] = website;
    } else if (website.includes("linkedin.com")) {
      socialMedia['linkedin'] = website;
    } else if (website.includes("facebook.com")) {
      socialMedia['facebook'] = website;
    } else if (website.includes("researchgate.net")) {
      socialMedia['researchgate'] = website;
    } else if (website.includes("orcid.org")) {
      socialMedia['orcid'] = website;
    } else if (website.includes("github.com")) {
      socialMedia['github'] = website;
    }
    else if (website.includes("europa-fördert-sachsen.de")) {
      socialMedia["esf"] = website;
    }
  });
  return socialMedia;
}

/**
 * Parses the address of people data.
 *
 * @param {Object} address - The address object.
 * @param {string} address.streetAddress - The street address.
 * @param {string} address.postalCode - The postal code.
 * @param {string} address.locality - The locality.
 * @returns {string|null} The parsed address or null if any of the required fields is missing.
 */
export const parsePeopleAddress = ({ streetAddress, postalCode, locality }) => {
  if (!streetAddress || !postalCode || !locality) {
    return null;
  }
  return `${streetAddress}, ${postalCode} ${locality}`;
};

/**
 * Parses the room information of people data.
 *
 * @param {Object} room - The room object.
 * @param {string} room.oldRoom - The old room.
 * @param {string} room.newRoom - The new room.
 * @returns {string|null} The parsed room information or null if both old and new rooms are missing.
 */
export const parsePeopleRoom = ({ oldRoom, newRoom }) => {
  if (newRoom) {
    return `${newRoom}`;
  } else if (oldRoom) {
    return oldRoom;
  } else {
    return null;
  }
};

export const parseTitle = positionArray => {
  for (let x = 0; x < positionArray.length; x++) {
    let pos = positionArray[x].label;

    if (pos.toLowerCase().startsWith('senior')) {
      return 'Dr.';
    }

    if (
      pos.toLowerCase().startsWith('prof') ||
      pos.toLowerCase().startsWith('dek')
    ) {
      return 'Prof. Dr.';
    }

    return '';
  }
};

export const parsePeoplePosition = (positionArray, context) => {
  if (!positionArray) return undefined;

  if (!Array.isArray(positionArray)) {
    return positionArray;
  }

  let fallbackPosition = positionArray[0].label;
  if (!context) return fallbackPosition;

  positionArray = positionArray.filter(x => !(x.label.toLowerCase().startsWith("associate") || x.label.toLowerCase().startsWith("assoziiert")));

  // Quick hack
  let orgContexts = [
    { de: 'professur', en: 'professorship' },
    { de: 'dekanat', en: "dean's office" },
    { de: 'urz wiss', en: 'urz wiss' }
  ];

  let selectedOrgaTerm = orgContexts.find(
    orgContext =>
      orgContext.de.startsWith(context.toLowerCase()) ||
      orgContext.en.startsWith(context.toLowerCase())
  );

  if (!selectedOrgaTerm) return fallbackPosition;
  for (let position of positionArray) {
    let positionOrgas = position.organizations
      .map(x => x.label)
      .join('/')
      .toLowerCase();
    if (
      positionOrgas.includes(selectedOrgaTerm.de) ||
      positionOrgas.includes(selectedOrgaTerm.en)
    )
      return position.label;
  }

  return fallbackPosition;
};

export const parsePeopleResearchAreas = (lst, n = 3) => {
  if (!lst || !Array.isArray(lst) || lst.length === 0) return undefined;
  const getNthEntries = lst.slice(0, n).map(entry => {
    return entry.label;
  });
  return getNthEntries.join(', ');
};

export const parsePublicationAuthors = authors => {
  if (!authors || !authors.length) return undefined;
  return authors.map(author => `${author.given} ${author.family}`).join(', ');
};

/**
 * Parses a comma separated or space separated string into an object.
 *
 * @param {string} str - The string to parse.
 * @returns {Object} The parsed object.
 * @example
 *
 * parseSeparatedStringIntoObject("key1, key2, key3");
 * // Returns { key1: true, key2: true, key3: true }
 *
 * parseSeparatedStringIntoObject("key1 key2 key3");
 * // Returns { key1: true, key2: true, key3: true }
 */
export const parseSeparatedStringIntoObject = str => {
  if (!str) {
    return {};
  }
  const delimiter = str.includes(',') ? ',' : ' ';
  const items = str ? str.split(delimiter).map(item => item.trim()) : [];
  return items.reduce((acc, item) => {
    acc[item] = true;
    return acc;
  }, {});
};

export function parseZodIssue(validationError, attributeName) {
  const issue = validationError.issues[0];
  switch (issue.code) {
    case 'invalid_enum_value':
      return `Invalid "${attributeName}" attribute: Invalid value, expected "${issue.options.join('" | "')}" but received "${issue.received}".`;
    case 'too_small':
      return `Invalid "${attributeName}" attribute: Expected at least ${issue.minimum} character.`;
    default:
      switch (issue.message) {
        case 'Required':
          return `"${attributeName}" attribute is mandatory, but missing.`;
        default:
          return `Invalid "${attributeName}" attribute: ` + issue.message;
      }
  }
}

export function mapDataciteToCSL(data) {
  // Parse authors from creators field
  const authors = data.creators.map(creator => {
    return { given: creator, family: creator };
  });

  // Format the issued date from publication_year
  const year = parseInt(data.publication_year.split('-')[0]);
  const month = parseInt(data.publication_year.split('-')[1]);
  const day = parseInt(data.publication_year.split('-')[2]);

  // Use tags for keywords
  const keywords = data.tags.map(tag => tag.name);

  // Construct the CSL object
  return {
    type: data.resource_type_general || 'dataset',
    title: data.title,
    author: authors,
    publisher: data.publisher,
    issued: {
      'date-parts': [[year, month, day]]
    },
    URL: data.url,
    keyword: keywords.join(', '),
    license: data.license_title
  };
}

export function mapPublicationToCSL(binding) {
  let out = structuredClone(binding);
  Object.keys(out).forEach(key => (out[key] = out[key].value));
  let titles = out['titles']
    .split('<!|!>')
    .filter(x => x !== '')
    .map(x => {
      return x.trim();
    });

  if (titles.length > 0) out['title'] = titles[0];

  out['author'] = out['authors']
    .split(';')
    .filter(x => x !== '')
    .map(x => {
      let orderAuthorPart = x.trim().split('::');
      let order = parseInt(orderAuthorPart[0].trim());
      let author = orderAuthorPart[1].trim();

      let authorSplit = author.split(' ', 2);
      let family = authorSplit[1].trim();
      let given = authorSplit[0].trim();
      return { family, given, order };
    })
    .toSorted((a, b) => a.order > b.order);

  let paper_type_mapper = {
    book: 'book',
    article: 'article-journal',
    'conference paper': 'paper-conference'
  };

  out['issued'] = { 'date-parts': [[+out['issued'], 0, 0]] };
  out['container-title'] = out['publisher'];
  out['type'] = paper_type_mapper[out['type']];
  out['DOI'] = out['doi']; // {"type": "issn", "id": binding["issn"]}];
  out['issn'] = out['ISSN'];

  return out;
}
