export default {
  title: {
    en: 'Title',
    de: 'Titel'
  },
  titleDe: {
    en: 'German title',
    de: 'Titel in Deutsch'
  },
  titleEn: {
    en: 'English title',
    de: 'Titel in Englisch'
  },
  resources: {
    en: 'Resources',
    de: 'Ressourcen'
  },
  researchAreas: {
    en: 'Research areas',
    de: 'Forschungsgebiete'
  },
  givenName: {
    en: 'First name',
    de: 'Vorname'
  },
  familyName: {
    en: 'Family name',
    de: 'Nachname'
  },
  telephone: {
    en: 'Phone',
    de: 'Telefon'
  },
  fax: {
    en: 'Fax',
    de: 'Fax'
  },
  email: {
    en: 'Email',
    de: 'E-Mail'
  },
  address: {
    en: 'Address',
    de: 'Adresse'
  },
  room: {
    en: 'Room',
    de: 'Raum'
  },
  call: {
    en: 'Call',
    de: 'Anrufen'
  },
  showProfilePageOf: {
    en: 'Show profile page of',
    de: 'Zeige die Profilseite von'
  },
  portraitOf: {
    en: 'Portrait of',
    de: 'Portrait von'
  },
  placeholderFor: {
    en: 'Placeholder for',
    de: 'Platzhalter für'
  },
  iconOfProject: {
    en: 'Icon of the project.',
    de: 'Icon des Projekts.'
  },
  iconOfService: {
    en: 'Icon of the service',
    de: 'Icon des Dienstes'
  },
  iconOfModule: {
    en: 'Icon of the module',
    de: 'Icon des Moduls'
  },
  moreResults: {
    en: 'more results',
    de: 'weitere Ergebnisse'
  },
  serviceResponsible: {
    en: 'person responsible',
    de: 'Verantwortlicher'
  },
  showMore: {
    en: 'show more',
    de: 'mehr anzeigen'
  },
  showLess: {
    en: 'show less',
    de: 'weniger anzeigen'
  },
  more: {
    en: 'more',
    de: 'mehr'
  },
  less: {
    en: 'less',
    de: 'weniger'
  },
  new: {
    en: 'new',
    de: 'neu'
  },
  description: {
    en: 'Description',
    de: 'Beschreibung'
  },
  startDate: {
    en: 'Start date',
    de: 'Startdatum'
  },
  endDate: {
    en: 'End date',
    de: 'Enddatum'
  },
  funder: {
    en: 'Funder',
    de: 'Drittmittelgeber'
  },
  // project create component
  type: {
    en: 'Type',
    de: 'Typ'
  },
  subtitle: {
    en: 'Subtitle',
    de: 'Untertitel'
  },
  abstract: {
    en: 'Abstract',
    de: 'Abstract'
  },
  status: {
    en: 'Status',
    de: 'Status'
  },
  openAccess: {
    en: 'Open Access',
    de: 'Open Access'
  },
  publishedIn: {
    en: 'Published in',
    de: 'Publiziert in'
  },
  publisher: {
    en: 'Publisher',
    de: 'Herausgeber'
  },
  publisherLocation: {
    en: 'Location of publisher',
    de: 'Verlagsort'
  },
  publicationYear: {
    en: 'Publication year',
    de: 'Erscheinungsjahr'
  },
  conferenceLocation: {
    en: 'Location of conference',
    de: 'Ort der Konferenz'
  },
  cite: {
    en: 'Cite',
    de: 'Zitieren'
  },
  noCitation: {
    en: 'No citation available.',
    de: 'Keine Zitation verfügbar.'
  },
  rawText: {
    en: 'Raw text',
    de: 'Rohdaten'
  },
  bibliography: {
    en: 'Bibliography',
    de: 'Bibliographie'
  },
  issue: {
    en: 'Issue',
    de: 'Issue'
  },
  volume: {
    en: 'Volume',
    de: 'Volume'
  },
  numberOfPages: {
    en: 'Number of pages',
    de: 'Seitenanzahl'
  },
  pageStart: {
    en: 'Page start',
    de: 'Erste Seite'
  },
  pageEnd: {
    en: 'Page end',
    de: 'Letzte Seite'
  },
  doi: {
    en: 'DOI',
    de: 'DOI'
  },
  isbn: {
    en: 'ISBN',
    de: 'ISBN'
  },
  issn: {
    en: 'ISSN',
    de: 'ISSN'
  },
  url: {
    en: 'URL',
    de: 'URL'
  },
  book: {
    en: 'Book',
    de: 'Buch'
  },
  bookChapter: {
    en: 'Book Chapter',
    de: 'Buchkapitel'
  },
  conferencePaper: {
    en: 'Conference Paper',
    de: 'Konferenz-Paper'
  },
  demo: {
    en: 'Demo',
    de: 'Demo'
  },
  journalArticle: {
    en: 'Journal Article',
    de: 'Journalartikel'
  },
  poster: {
    en: 'Poster',
    de: 'Poster'
  },
  presentation: {
    en: 'Presentation',
    de: 'Präsentation'
  },
  symposiumPaper: {
    en: 'Symposium Paper',
    de: 'Symposium-Paper'
  },
  thesis: {
    en: 'Thesis',
    de: 'Thesis'
  },
  workshopPaper: {
    en: 'Workshop Paper',
    de: 'Workshop-Paper'
  },
  author: {
    en: 'Author',
    de: 'Autor'
  },
  authors: {
    en: 'Authors',
    de: 'Autoren'
  },
  authorAdd: {
    en: 'Add author',
    de: 'Weiteren Autor hinzufügen'
  },
  addViaOrcid: {
    en: 'Add author via ORCID',
    de: 'Weiteren Autor über ORCID hinzufügen'
  },
  authorRemove: {
    en: 'Remove author',
    de: 'Autor entfernen'
  },
  remove: {
    en: 'remove',
    de: 'entfernen'
  },
  orcid: {
    en: 'ORCID',
    de: 'ORCID'
  },
  orcidExample: {
    en: 'For example: 0000-0001-0012-1234',
    de: 'Zum Beispiel: 0000-0001-0012-1234'
  },
  authorFirstnames: {
    en: 'First names',
    de: 'Vornamen'
  },
  authorLastname: {
    en: 'Last name',
    de: 'Nachname'
  },
  year: {
    en: 'Year',
    de: 'Jahr'
  },
  yes: {
    en: 'Yes',
    de: 'Ja'
  },
  no: {
    en: 'No',
    de: 'Nein'
  },
  unknown: {
    en: 'Unknown',
    de: 'Unbekannt'
  },
  unspecified: {
    en: 'Unspecified',
    de: 'Nicht spezifiziert'
  },
  accepted: {
    en: 'Accepted',
    de: 'Angenommen'
  },
  submitted: {
    en: 'Submitted',
    de: 'Eingereicht'
  },
  rejected: {
    en: 'Rejected',
    de: 'Abgelehnt'
  },
  workInProgress: {
    en: 'Work in Progress',
    de: 'Work in Progress'
  },
  other: {
    en: 'Other',
    de: 'Andere'
  },
  // UI labels
  submit: {
    en: 'Submit',
    de: 'Absenden'
  },
  cancel: {
    en: 'Cancel',
    de: 'Abbrechen'
  },
  resolveDoi: {
    en: 'Resolve DOI',
    de: 'DOI auflösen'
  },
  help: {
    en: 'Help',
    de: 'Hilfe'
  },
  search: {
    en: 'Search',
    de: 'Suchen'
  },
  close: {
    en: 'Close',
    de: 'Schließen'
  },
  speaker: {
    en: 'Speaker',
    de: 'Refernt'
  },
  // success messages
  successProjectCreated: {
    en: 'Project created successfully!',
    de: 'Projekt erfolgreich erstellt!'
  },
  successPublicationForwarded: {
    en: 'Publication has been forwarded for review.',
    de: 'Publikation wurde zur Überprüfung weitergeleitet.'
  },
  successDatasetForwarded: {
    en: 'Dataset has been forwarded for review.',
    de: 'Datensatz wurde zur Überprüfung weitergeleitet.'
  },
  successDatasetAccepted: {
    en: 'Dataset accepted successfully!',
    de: 'Datensatz erfolgreich akzeptiert!'
  },
  successDatasetRejected: {
    en: 'Dataset rejected.',
    de: 'Datensatz abgelehnt.'
  },
  successDatasetRevised: {
    en: 'Dataset revised successfully!',
    de: 'Datensatz erfolgreich überarbeitet!'
  },
  successPublicationAccepted: {
    en: 'Publication accepted successfully!',
    de: 'Publikation erfolgreich akzeptiert!'
  },
  successPublicationRejected: {
    en: 'Publication rejected.',
    de: 'Publikation abgelehnt.'
  },
  successPublicationRevised: {
    en: 'Publication revised successfully!',
    de: 'Publikation erfolgreich überarbeitet!'
  },
  // error messages
  errorMissingPermission: {
    en: 'Error: Missing permission.',
    de: 'Fehler: Fehlende Berechtigung.'
  },
  errorNetwork: {
    en: 'Error: A Network Error occurred.',
    de: 'Fehler: Ein Netzwerkfehler ist aufgetreten.'
  },
  errorOccurred: {
    en: 'Error: An error occurred.',
    de: 'Fehler: Ein Fehler ist aufgetreten.'
  },
  password: {
    en: 'Password',
    de: 'Passwort'
  },
  noProfessorshipSpecified: {
    en: 'no professorship specified',
    de: 'Keine Professur angegeben'
  },
  noTitleSpecified: {
    en: 'no service title specified',
    de: 'Kein Dienstname angegeben'
  },
  noDescriptionSpecified: {
    en: 'no abstract specified',
    de: 'Kein Abstract angegeben'
  },
  noneSpecified: {
    en: 'none specified',
    de: 'nicht angegeben'
  },
  noFacultySpecified: {
    en: 'no faculty specified',
    de: 'Keine Fakultät angegeben'
  },
  createAService: {
    en: 'Create a new service',
    de: 'Anlegen eines neuen Dienstes'
  },
  postOkay: {
    en: 'Successfully created a new service',
    de: 'Anlegen des neuen Dienstes war erfolgreich'
  },
  postNotOkay: {
    en: 'Failed to create a new service',
    de: 'Anlegen des neuen Dienstes fehlgeschlagen'
  },
  delete: {
    en: 'Delete',
    de: 'Löschen'
  },

  confirmDeleteService: {
    en: 'Do you really want to delete this service?',
    de: 'Wollen Sie diesen Dienst wirklich löschen?'
  },

  languageSelect: {
    en: 'Select a language',
    de: 'Sprache auswählen'
  },

  keywordsDe: {
    en: 'Free keywords, separated by comma or semicolon, German',
    de: 'Freie Schlagwörter, durch Komma oder Semikolon getrennt, Deutsch'
  },
  keywordsEn: {
    en: 'Free keywords, separated by comma or semicolon, English',
    de: 'Freie Schlagwörter, durch Komma oder Semikolon getrennt, Englisch'
  },
  conferenceTitle: {
    de: 'Titel der Konferenz',
    en: 'Conference title'
  },
  authorNotFound: {
    de: 'Kein Autor mit diesem Namen gefunden.',
    en: 'Author with this name not found.'
  },
  optional: {
    de: ' (optional)',
    en: ' (optional)'
  },
  requiredSpecification: {
    de: 'Die Angabe von DOI, ISBN, ISSN oder URL ist Pflicht.',
    en: 'The specification of DOI, ISBN, ISSN or URL is required.'
  },
  atLeastOneLanguageRequired: {
    de: 'Zumindest eine der Sprachen muss eingegeben werden.',
    en: 'At least one of the languages must be specified.'
  },

  accept: {
    de: 'Akzeptieren',
    en: 'Accept'
  },
  reject: {
    de: 'Ablehnen',
    en: 'Reject'
  },
  noPublicationsFound: {
    de: 'Keine Publikationen gefunden.',
    en: 'No publications found.'
  },

  notes: {
    en: 'Description',
    de: 'Beschreibung'
  },
  tags: {
    en: 'Tags',
    de: 'Tags'
  },
  dataLicense: {
    en: 'Data license',
    de: 'Datenlizenz'
  },
  version: {
    en: 'Version',
    de: 'Version'
  },
  author_email: {
    en: 'Author Email',
    de: 'E-Mail des Autors'
  },
  maintainer: {
    en: 'Maintainer',
    de: 'Maintainer'
  },
  maintainer_email: {
    en: 'Maintainer Email',
    de: 'E-Mail des Maintainers'
  },
  extras_field: {
    en: 'Custom Field',
    de: 'Benutzerdefiniertes Feld'
  },
  extras_key: {
    en: 'Key',
    de: 'Schlüssel'
  },
  extras_value: {
    en: 'Value',
    de: 'Wert'
  },
  dataType: {
    en: 'Data type',
    de: 'Datentyp'
  },
  data: {
    en: 'Data',
    de: 'Daten'
  },
  resourceType: {
    en: 'Resource type',
    de: 'Ressourcentyp'
  },
  resourceTypeGeneral: {
    en: 'General resource type',
    de: 'Allgemeiner Ressourcentyp'
  },
  noDatasetsFound: {
    de: 'Keine Datensätze gefunden.',
    en: 'No datasets found.'
  },

  fundingAmount: {
    de: 'Förderbetrag',
    en: 'Funding amount'
  },
  fundingAmountInEuro: {
    de: 'Förderbetrag (in €)',
    en: 'Funding amount (in €)'
  },
  projectHeads: {
    de: 'Projektleiter*innen',
    en: 'Project Heads'
  },
  projectHeadAdd: {
    de: 'Projektleiter*in hinzufügen',
    en: 'Add Project Head'
  },
  projectHeadRemove: {
    de: 'Projektleiter*in entfernen',
    en: 'Remove Project Head'
  },
  projectHeadName: {
    en: 'Project head',
    de: 'Projektleiter*in'
  },
  sustainableDevelopmentGoals: {
    de: 'Ziele für nachhaltige Entwicklung',
    en: 'Sustainable development goals'
  },
  sustainableDevelopmentGoal: {
    de: 'Ziel für nachhaltige Entwicklung',
    en: 'Sustainable development goal'
  },
  partners: {
    de: 'Partner*innen',
    en: 'Partners'
  },
  partnerAdd: {
    de: 'Partner*in hinzufügen',
    en: 'Add partner'
  },
  partnerRemove: {
    de: 'Partner*in entfernen',
    en: 'Partner entfernen'
  },
  fieldsOfResearch: {
    de: 'Forschungsbereiche',
    en: 'Fields of research'
  },
  fieldOfResearch: {
    de: 'Forschungsbereich',
    en: 'Field of research'
  },
  fieldOfResearchAdd: {
    de: 'Weiteren Forschungsbereich hinzufügen',
    en: 'Add field of research'
  },
  fieldOfResearchRemove: {
    de: 'Forschungsbereich entfernen',
    en: 'Remove field of research'
  },
  moduleName: {
    de: 'Modulname',
    en: 'Module name'
  },
  workload: {
    de: 'Arbeitsaufwand',
    en: 'Workload'
  },

  durationOfTheModule: {
    de: 'Dauer des Moduls',
    en: 'Duration of the module'
  },
  contentsAndQualificationObjectives: {
    de: 'Inhalte und Qualifikationsziele',
    en: 'Contents and qualification objectives'
  },
  teachingForms: {
    de: 'Lehrformen',
    en: 'Teaching forms'
  },
  creditsAndGrades: {
    de: 'Leistungspunkte und Noten',
    en: 'Credits and grades'
  },
  moduleNumber: {
    de: 'Modulnummer',
    en: 'Module number'
  },
  moduleExamination: {
    de: 'Modulprüfung',
    en: 'Module examination'
  },
  moduleResponsibility: {
    de: 'Modulverantwortlich',
    en: 'Module responsibility'
  },
  applicabilityOfTheModule: {
    de: 'Verwendbarkeit des Moduls',
    en: 'Applicability of the module'
  },
  prerequisitesForParticipation: {
    de: 'Voraussetzungen für die Teilnahme (empfohlene Kenntnisse und Fähigkeiten)',
    en: 'Prerequisites for participation (recommended knowledge and skills)'
  },
  prerequisitesForTheAwardOfCreditPoints: {
    de: 'Voraussetzungen für die Vergabe von Leistungspunkten',
    en: 'Prerequisites for the award of credit points'
  }
};
