<svelte:options
  customElement={{
    tag: 'tucfis-service'
  }}
/>

<script>
  import { setContext } from 'svelte';

  import { getListData } from '../services/accessControl.js';

  import Service from '../components/service/Service.svelte';
  import ShowError from '../components/ShowError.svelte';
  import Skeleton from '../components/shared/LoadingSkeletonSquare.svelte';

  export let id; // https://tucid.tu-chemnitz.de/giim/individual/n6928
  export let lang = 'en';
  setContext('language', lang);

  const fetchService = async () => {
    const dataReturned = await getListData('services');
    return dataReturned.find(item => item.id === id);
  };
</script>

{#await fetchService()}
  <Skeleton />
{:then service}
  <Service
    id={service.id}
    title={service.name}
    description={service.description}
    manager={service.manager}
    image={service.iconSrc}
  />
{:catch error}
  <ShowError message={'Error when loading data: ' + error.toString()} />
{/await}
