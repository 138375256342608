<div class="skeleton-wrapper">
  <div class="skeleton-avatar"></div>
  <div class="skeleton-text-wrapper">
    <div class="skeleton-text short"></div>
    <div class="skeleton-text short"></div>
    <div class="skeleton-text long"></div>
    <div class="skeleton-text"></div>
    <div class="skeleton-text long"></div>
    <div class="skeleton-text long"></div>
    <div class="skeleton-text"></div>
    <div class="skeleton-text"></div>
  </div>
  <!-- ... more skeleton text divs as needed ... -->
</div>

<style lang="scss">
  .skeleton-wrapper {
    //width: 100%;
    border-radius: 4px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .skeleton-avatar {
    width: 200px;
    height: 200px;
    background-color: #e0e0e0;
    border-radius: 50%;
    animation: glow 1.5s infinite ease-in-out;
    flex-shrink: 0;
  }

  .skeleton-text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
  }

  .skeleton-text {
    height: 20px;
    background-color: #e0e0e0;
    animation: glow 1.5s infinite ease-in-out;
  }

  .skeleton-text.short {
    width: 25%;
  }

  .skeleton-text.long {
    width: 75%;
  }

  @keyframes glow {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #f0f0f0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
</style>
