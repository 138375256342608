<svelte:options
  customElement={{
    tag: 'tucfis-publication'
  }}
/>

<script>
  import { setContext } from 'svelte';

  import { getListData } from '../services/accessControl.js';

  import Publication from '../components/publication/Publication.svelte';
  import ShowError from '../components/ShowError.svelte';
  import ProjectSkeleton from '../components/shared/LoadingSkeletonSquare.svelte';
  import CitationDialog from '../components/shared/citation/CitationDialog.svelte';

  export let id; // doi:10.1007/978-3-319-08245-5_32
  export let lang = 'en';

  setContext('language', lang);

  const fetchPublication = async () => {
    const dataReturned = await getListData('publications', {
      orgUnit: '426'
    });
    return dataReturned.find(item => item.DOI === id);
  };

  let citationDialog;

  const showCitationDialog = data => citationDialog.showCitation(data);
</script>

{#await fetchPublication()}
  <ProjectSkeleton />
{:then publication}
  <div>
    <CitationDialog bind:this={citationDialog} />
    <Publication
      publication={publication.publication}
      title={publication.title}
      authors={publication.author}
      publisher={publication.publisher}
      DOI={publication.DOI}
      data={publication}
      {showCitationDialog}
    />
  </div>
{:catch error}
  <ShowError message={'Error when loading data: ' + error.toString()} />
{/await}
