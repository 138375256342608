<script>
  import { getContext } from "svelte";
  import i18n_dictionary from "../../config/i18n.js";

  export let description;

  const MAX_CHARS = 500; // Change this to the maximum number of characters you want to show

  let showFullDescription = false;

  function toggleDescription() {
    showFullDescription = !showFullDescription;
  }

  function truncateDescription(descriptionToTruncate) {
    if (!descriptionToTruncate) return "";
    return descriptionToTruncate.length > MAX_CHARS
      ? descriptionToTruncate.substring(0, MAX_CHARS) + "..."
      : descriptionToTruncate;
  }

  function isLongDescription(descriptionToCheck) {
    if (!descriptionToCheck) return false;
    return descriptionToCheck.length > MAX_CHARS;
  }

  const i18n = (key) => {
    return i18n_dictionary[key][getContext("language")];
  };
</script>

<div id="description-container">
  {#if showFullDescription}
    <p>{@html description}</p>
    {#if isLongDescription(description)}
      <button on:click={toggleDescription}>{i18n("less")}</button>
    {/if}
  {:else}
    <p>{@html truncateDescription(description)}</p>
    {#if isLongDescription(description)}
      <button on:click={toggleDescription}>{i18n("more")}</button>
    {/if}
  {/if}
</div>

<style lang="scss">
  #description-container {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }

  p {
    margin: 0;
  }
  /* button as link style that underlines on hover */
  button {
    background: none;
    border: none;
    padding: 4px;
    font: inherit;
    cursor: pointer;
    color: var(--primary-color);
    text-decoration: underline;
    margin: 0;
    font-weight: 600;
  }

  button:hover {
    color: black;
  }

  button:active {
    color: black;
  }
</style>
