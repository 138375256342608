<svelte:options
  customElement={{
    tag: 'tucfis-demo-page',
    shadow: 'none'
  }}
/>

<script>
  export let logosrc;
  import Navbar from '../components/demo/Navbar.svelte';
  import Page from '../components/demo/Page.svelte';
  import ComponentSection from '../components/demo/ComponentSection.svelte';
  import { onMount } from 'svelte';

  let currentPage = 'person';
  const pages = [
    'person',
    'project',
    'publication',
    'event',
    'service',
    'dataset',
    'project-list'
  ];

  onMount(() => {
    updateCurrentPage();
    window.addEventListener('popstate', updateCurrentPage);
    return () => {
      window.removeEventListener('popstate', updateCurrentPage);
    };
  });

  function updateCurrentPage() {
    const urlParams = new URLSearchParams(window.location.search);
    const pageParam = urlParams.get('page');
    if (pageParam && pages.includes(pageParam)) {
      currentPage = pageParam;
    } else {
      currentPage = 'person'; // Default to 'person' if no valid query param
    }
  }

  function handlePageChange(event) {
    currentPage = event.detail.page;
    const url = new URL(window.location);
    url.searchParams.set('page', currentPage);
    history.pushState({}, '', url);
  }
</script>

<Navbar {logosrc} {currentPage} on:pageChange={handlePageChange} />

{#if currentPage === 'person'}
  <Page title="People">
    <ComponentSection
      title="People-single"
      params={[
        { name: 'id', description: 'The id of the person' },
        {
          name: 'show',
          description: 'A space separated list of the properties to show:',
          subItems: [
            'positions: The positions of the person',
            'research-areas: The research areas of the person'
          ]
        },
        {
          name: 'lang',
          description: 'The language of the component:',
          subItems: ['de: The german language', 'en: The english language']
        },
        {
          name: 'style',
          description: 'The style of the component:',
          subItems: [
            '--primary-color: The primary color of the component',
            '--font-family: The font family of the component',
            '--font-size: The font size of the component'
          ]
        }
      ]}
      examples={[
        {
          title: 'Example basic usage',
          code: `<tucfis-people
  show="positions research-areas"
  id="https://tucid.tu-chemnitz.de/giim/individual/person-57811"
></tucfis-people>`
        },
        {
          title: 'Example change language to German',
          code: `<tucfis-people
  lang="de"
  show="positions research-areas"
  id="https://tucid.tu-chemnitz.de/giim/individual/person-57811"
></tucfis-people>`
        },
        {
          title: 'Example change style',
          code: `<tucfis-people
  show="positions research-areas"
  id="https://tucid.tu-chemnitz.de/giim/individual/person-57811"
  style="
        --primary-color: #EC9B29;
        --font-family: 'Courier New', monospace;
        --font-size: 20px;
      "
></tucfis-people>`
        }
      ]}
    ></ComponentSection>
    <hr />

    <ComponentSection
      title="People-list"
      params={[
        {
          name: 'entity',
          description: 'The type of the list:',
          subItems: ['people: The list of people']
        },
        {
          name: 'org-unit',
          description: 'The org-unit of the list:',
          subItems: ['struktureinheit-255030: The professorship context']
        },
        {
          name: 'context',
          description: 'The context of the list:',
          subItems: ['professorship: The professorship context']
        },
        {
          name: 'lang',
          description: 'The language of the component:',
          subItems: ['de: The german language', 'en: The english language']
        },
        {
          name: 'style',
          description: 'The style of the component:',
          subItems: [
            '--primary-color: The primary color of the component',
            '--font-family: The font family of the component',
            '--font-size: The font size of the component'
          ]
        }
      ]}
      examples={[
        {
          title: 'Example basic usage',
          code: `<tucfis-list
  entity="people"
  org-unit="struktureinheit-255030"
  limit-to=3
  sort="ascending"
  sort-by="positions"
  context="professorship"
></tucfis-list>`
        },
        {
          title: 'Example change language to German',
          code: `<tucfis-list
  lang="de"
  entity="people"
  org-unit="struktureinheit-255030"
  limit-to=3
  sort="ascending"
  sort-by="positions"
  context="professorship"
  show-filter-bar
></tucfis-list>`
        },
        {
          title: 'Example change style',
          code: `<tucfis-list
  entity="people"
  org-unit="struktureinheit-255030"
  limit-to=3
  sort="ascending"
  sort-by="positions"
  context="professorship"
  style="
        --primary-color: #EC9B29;
        --font-family: 'Courier New', monospace;
        --font-size: 20px;
      "
></tucfis-list>`
        }
      ]}
    ></ComponentSection>
  </Page>
{:else if currentPage === 'project'}
  <Page title="Projects">
    <ComponentSection
      title="Project-single"
      params={[{ name: 'id', description: 'The id of the project' }]}
      examples={[
        {
          title: 'Example Basic Usage',
          code: `<tucfis-project
  id="https://tucid.tu-chemnitz.de/giim/individual/project-gepris-416228727"
></tucfis-project>`
        }
      ]}
    ></ComponentSection>

    <hr />

    <ComponentSection
      title="Project-list"
      params={[
        {
          name: 'entity',
          description: 'The type of the list:',
          subItems: ['projects: The list of projects']
        },
        {
          name: 'org-unit',
          description: 'The org-unit of the list:',
          subItems: ['struktureinheit-255030: The professorship context']
        }
      ]}
      examples={[
        {
          title: 'Example Basic Usage',
          code: `<tucfis-list
  entity="projects"
  org-unit="struktureinheit-255030"
  sort-by="start-date"
></tucfis-list>`
        }
      ]}
    ></ComponentSection>
  </Page>
{:else if currentPage === 'publication'}
  <Page title="Publications">
    <ComponentSection
      title="Publication-single"
      params={[{ name: 'id', description: 'The id of the publication' }]}
      examples={[
        {
          title: 'Example Basic Usage',
          code: `<tucfis-publication id="doi:10.1007/978-3-319-08245-5_32">
</tucfis-publication>`
        }
      ]}
    ></ComponentSection>

    <hr />

    <ComponentSection
      title="Publication-list"
      params={[
        {
          name: 'entity',
          description: 'The type of the list:',
          subItems: ['publications: The list of publications']
        },
        {
          name: 'org-unit',
          description: 'The org-unit of the list'
        }
      ]}
      examples={[
        {
          title: 'Example Basic Usage',
          code: `<tucfis-list
  entity="publications"
  org-unit="426"
  sort="ascending"
  sort-by="publisher"
  show-filter-bar
></tucfis-list>`
        }
      ]}
    ></ComponentSection>
  </Page>
{:else if currentPage === 'event'}
  <Page title="Events">
    <ComponentSection
      title="Event-list"
      params={[
        {
          name: 'entity',
          description: 'The type of the list:',
          subItems: ['events: The list of events']
        }
      ]}
      examples={[
        {
          title: 'Example Basic Usage',
          code: `<tucfis-list
  show-filter-bar
  entity="events"
  sort="descending"
  sort-by="date"
></tucfis-list>`
        }
      ]}
    ></ComponentSection>
  </Page>
{:else if currentPage === 'service'}
  <Page title="Services">
    <ComponentSection
      title="Service-single"
      params={[{ name: 'id', description: 'The id of the service' }]}
      examples={[
        {
          title: 'Example Basic Usage',
          code: `<tucfis-service id="https://tucid.tu-chemnitz.de/giim/individual/n6928">
</tucfis-service>`
        }
      ]}
    ></ComponentSection>

    <hr />

    <ComponentSection
      title="Service-list"
      params={[
        {
          name: 'entity',
          description: 'The type of the list:',
          subItems: ['services: The list of services']
        }
      ]}
      examples={[
        {
          title: 'Example Basic Usage',
          code: `<tucfis-list
  entity="services"
  show-filter-bar
></tucfis-list>`
        }
      ]}
    ></ComponentSection>
  </Page>
{:else if currentPage === 'dataset'}
  <Page title="Datasets">
    <ComponentSection
      title="Dataset-single"
      params={[{ name: 'id', description: 'The id of the dataset' }]}
      examples={[
        {
          title: 'Example Basic Usage',
          code: `<tucfis-dataset id="example"></tucfis-dataset>`
        }
      ]}
    ></ComponentSection>

    <hr />

    <ComponentSection
      title="Dataset-list"
      params={[
        {
          name: 'entity',
          description: 'The type of the list:',
          subItems: ['datasets: The list of datasets']
        },
        {
          name: 'org-unit',
          description: 'The org-unit of the list'
        }
      ]}
      examples={[
        {
          title: 'Example Basic Usage',
          code: `<tucfis-list
  lang="en"
  entity="datasets"   
  org-unit="testorga"
></tucfis-list>`
        }
      ]}
    ></ComponentSection>
  </Page>
{:else if currentPage === 'project-list'}
  <Page title="Project-list">
    <tucfis-list
      entity="projects"
      org-unit="struktureinheit-255030"
      sort-by="start-date"
    >
    </tucfis-list>
  </Page>
{/if}

<style lang="scss">
  hr {
    margin: 50px 0;
  }
</style>
