<script>
  import CodeBlock from './CodeBlock.svelte';

  export let title;
  export let params = [];
  export let examples = [];
</script>

<section class="component-section">
  <h2>{title}</h2>
  <div class="component-params">
    <p>The component has the following params:</p>
    <ul>
      {#each params as param}
        <li>
          <strong>{param.name}:</strong> {param.description}
          {#if param.subItems}
            <ul>
              {#each param.subItems as subItem}
                <li>{subItem}</li>
              {/each}
            </ul>
          {/if}
        </li>
      {/each}
    </ul>
  </div>
  {#each examples as example}
    <div class="examples-container">
      <div class="example-title">
        <h3>{example.title}</h3>
      </div>
      <div class="component-example">
        {@html example.code}
      </div>
      <CodeBlock code={example.code.trim()} />
    </div>
  {/each}
</section>

<style lang="scss">
  .component-section {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .examples-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 40px;
    column-gap: 30px;

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
  }

  .example-title {
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    gap: 18px;

    @media (max-width: 1024px) {
      grid-column: span 1;
    }
  }

  .component-params {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .component-params ul {
    list-style-type: none;
    padding: 0;
  }

  .component-params ul > li {
    margin-bottom: 10px;
  }

  .component-params ul ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-top: 10px;
  }

  h2,
  h3 {
    margin: 0;
  }
</style>