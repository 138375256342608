<script>
  import { getContext } from 'svelte';
  import i18n_dictionary from '../../config/i18n.js';

  export let description;

  let domParser = new DOMParser();

  $: if (description) {
    description = domParser.parseFromString(description, 'text/html').body
      .firstChild.textContent;
  }

  const MAX_CHARS = 500; // Change this to the maximum number of characters you want to show

  let showFullDescription = false;

  function toggleDescription() {
    showFullDescription = !showFullDescription;
  }

  function truncateDescription(descriptionToTruncate) {
    if (!descriptionToTruncate) return '';
    return descriptionToTruncate.length > MAX_CHARS
      ? descriptionToTruncate.substring(0, MAX_CHARS) + '...'
      : descriptionToTruncate;
  }

  function isLongDescription(descriptionToCheck) {
    if (!descriptionToCheck) return false;
    return descriptionToCheck.length > MAX_CHARS;
  }

  const i18n = key => {
    return i18n_dictionary[key][getContext('language')];
  };
</script>

{#if description && description !== ''}
  <div id="description-container">
    {#if showFullDescription}
      <p>{description}</p>
      {#if isLongDescription(description)}
        <button on:click={toggleDescription}>{i18n('less')}</button>
      {/if}
    {:else}
      <p>{truncateDescription(description)}</p>
      {#if isLongDescription(description)}
        <button on:click={toggleDescription}>{i18n('more')}</button>
      {/if}
    {/if}
  </div>
{/if}

<style lang="scss">
  #description-container {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  p {
    margin: 0;
    font-style: italic;
  }
  /* button as link style that underlines on hover */
  button {
    background: none;
    border: none;
    padding: 4px;
    font: inherit;
    cursor: pointer;
    color: var(--primary-color);
    text-decoration: underline;
    margin: 0;
    font-weight: 600;
    font-style: italic;
  }

  button:hover {
    color: black;
  }

  button:active {
    color: black;
  }
</style>
