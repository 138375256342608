<script>
  import { getContext } from 'svelte';
  import i18n_dictionary from '../../config/i18n.js';
  import Description from './Description.svelte';
  import Image from '../shared/Image.svelte';

  export let id;
  export let title;
  export let description;
  export let manager;
  export let image;

  const i18n = key => {
    return i18n_dictionary[key][getContext('language')];
  };
</script>

<div id="main-container">
  <Image
    src={image}
    alt={i18n('iconOfProject')}
    placeHolderAlt={`${i18n('placeholderFor')}: ${i18n('iconOfProject')}`}
    reroute={{
      route: 'https://fis.tu-chemnitz.de/vivo/',
      rerouteTo: 'https://vsr.informatik.tu-chemnitz.de/tucfisWebComponents/'
    }}
  />
  <div id="text-container">
    <h3>
      <a href={id} {title}>{title}</a>
    </h3>
    <Description {description} />
    {#if manager}
      <p id="manager">
        <span>
          {i18n('serviceResponsible')}:
        </span>
        <span>
          {manager}
        </span>
      </p>
    {/if}
  </div>
</div>

<style lang="scss">
  #main-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 10px;
  }

  #text-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 0 4px;
    gap: 20px;
  }

  #text-container h3 {
    font-size: calc(var(--font-size) * 1.05);
    font-weight: bold;
    margin: 0;
    color: var(--primary-color);
  }

  #start-date {
    font-weight: bold;
    margin: 0;
  }

  #text-container a {
    color: var(--primary-color);
    text-decoration: none;
  }

  #text-container a:hover {
    text-decoration: underline;
  }

  #manager {
    margin: 0;
  }

  #manager :first-child {
    font-weight: bold;
  }
</style>
