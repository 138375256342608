<script>
  //export let language = "de";
  import LinkedIn from '../icons/LinkedIn.svelte';
  import Facebook from '../icons/Facebook.svelte';
  import X from '../icons/X.svelte';
  import ResearchGate from '../icons/ResearchGate.svelte';
  import Image from './Image.svelte';
  import { getContext } from 'svelte';
  import i18n_dictionary from '../../config/i18n.js';
  import Orcid from '../icons/orcid.svelte';
  import GitHub from '../icons/GitHub.svelte';
  import Esf from '../icons/ESF.svelte';

  export let id;
  export let email;
  export let position;
  export let firstName;
  export let lastName;
  export let telephone;
  export let fax;
  export let address;
  export let room;
  export let researchAreas;
  export let image;
  export let socialMedia = undefined;
  export let title = '';
  export let reverse = false;

  const i18n = key => {
    return i18n_dictionary[key][getContext('language')];
  };
</script>

<div class="main-container">
  <div class="image-column" style="order: {reverse ? 1 : -1}">
    <Image
      src={image}
      alt={`${i18n('portraitOf')} ${firstName} ${lastName}`}
      placeHolderAlt={`${i18n('placeholderFor')}: ${i18n(
        'portraitOf'
      )} ${firstName} ${lastName}`}
      reroute={{
        route: 'https://fis.tu-chemnitz.de/vivo/',
        rerouteTo: 'https://vsr.informatik.tu-chemnitz.de/tucfisWebComponents/'
      }}
    />
  </div>
  <div class="text-column">
    <div id="text-title">
      {#if id}
        <a href={id}>
          <h1>{`${title} ${firstName} ${lastName}`}</h1>
        </a>
      {:else}
        <h1>{`${title} ${firstName} ${lastName}`}</h1>
      {/if}
      {#if position}
        <h2>{position}</h2>
      {/if}
    </div>
    <ul>
      <li>
        <span>{i18n('email')}: </span>
        <span><a href={`mailto:${email}`}>{email}</a></span>
      </li>
      {#if address}
        <li>
          <span>{i18n('address')}: </span>
          <span>{address}</span>
        </li>
      {/if}
      {#if room}
        <li>
          <span>{i18n('room')}: </span>
          <span>{room}</span>
        </li>
      {/if}
      {#if researchAreas}
        <li>
          <span>{i18n('researchAreas')}: </span>
          <span>{researchAreas}</span>
        </li>
      {/if}
      {#if telephone}
        <li>
          <span>{i18n('telephone')}: </span>
          <span
            ><a href="tel:{telephone}">
              {telephone}
            </a></span
          >
        </li>
      {/if}
      {#if fax}
        <li>
          <span>{i18n('fax')}: </span>
          <span>{fax}</span>
        </li>
      {/if}
    </ul>
  </div>
  <div class="social-column" style="order: {reverse ? -1 : 1}">
    <div id="social-icons-container" class:hidden={!socialMedia}>
      {#if socialMedia}
        {#if socialMedia.linkedin}
          <a href={socialMedia.linkedin} target="_blank">
            <LinkedIn />
          </a>
        {/if}
        {#if socialMedia.facebook}
          <a href={socialMedia.facebook} target="_blank">
            <Facebook />
          </a>
        {/if}
        {#if socialMedia.twitter}
          <a href={socialMedia.twitter} target="_blank">
            <X />
          </a>
        {/if}
        {#if socialMedia.researchgate}
          <a href={socialMedia.researchgate} target="_blank">
            <ResearchGate />
          </a>
        {/if}
        {#if socialMedia.orcid}
          <a href={socialMedia.orcid} target="_blank">
            <Orcid />
          </a>
        {/if}
        {#if socialMedia.github}
          <a href={socialMedia.github} target="_blank">
            <GitHub />
          </a>
        {/if}
        {#if socialMedia.esf}
          <a href={socialMedia.esf} target="_blank">
            <Esf />
          </a>
        {/if}
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  .main-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    margin: 0;
    align-items: stretch;
    justify-items: stretch;
    gap: 20px;

    @media (max-width: 1300px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto;
      gap: 10px;
      align-items: stretch;

      /* Ensure 'reverse' has no effect on mobile */
      & > .image-column {
        order: -1 !important;
      }
      & > .text-column {
        order: 0 !important;
      }
      & > .social-column {
        order: 1 !important;
      }
    }
  }

  .image-column,
  .social-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;

    /* Ensure the image takes full width on mobile */
    @media (max-width: 1300px) {
      width: 100%;
      align-items: center;
    }
  }

  .text-column {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    height: 100%;
    gap: 12px;

    /* Add margin to prevent elements from cuddling */
    margin-top: 10px;
    @media (max-width: 1300px) {
      width: 100%;
      align-items: center;
    }
  }

  .text-column ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .text-column li {
    margin: 5px 0;
  }

  .text-column a {
    font-weight: bold;
    color: var(--primary-color);
    text-decoration: none;
  }

  .text-column a:hover {
    text-decoration: underline;
  }

  #text-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
  }

  #text-title h1 {
    font-size: calc(var(--font-size) * 1.07);
    text-transform: uppercase;
    margin: 0;
    color: var(--primary-color);
  }

  #text-title h2 {
    font-size: calc(var(--font-size) * 1.07);
    font-weight: normal;
    font-style: italic;
    margin: 0;
    color: #777;
    text-transform: uppercase;
  }

  #social-icons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 9px;
  }

  .hidden {
    visibility: hidden;
  }

  #social-icons-container > a {
    width: 1.85em;
    color: #777;
  }

  #social-icons-container > a:hover {
    color: var(--primary-color);
  }
</style>
