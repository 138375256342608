import accessSettings from '../config/settings.js';
import { fetchJson } from '../utils/fetching';
import { mapDataciteToCSL, mapPublicationToCSL } from '../utils/parsers.js';

export const getElementFromAC = async (type, id) => {
  const url =
    accessSettings.ENDPOINTS.FIS_AC + `${type}?tucid=${encodeURI(id)}`;
  const element = await fetchJson(() => {
    return fetch(url, {
      method: 'GET',
      mode: 'cors'
    });
  });

  return element;
};

export const getListData = async (typeOfData, settings) => {
  const filterConfig = settings?.filterConfig;
  const orgUnit = settings?.orgUnit;

  if (typeOfData == 'publications') {
    return await getPublicationById(orgUnit);
  } else if (typeOfData == 'datasets') {
    return await getDatasetFromGroup(orgUnit);
  }

  const filterOperationAttrMap = {
    equals: 'EQUALS',
    includes: 'CONTAINS',
    'starts-with': 'STARTS_WITH',
    'ends-with': 'ENDS_WITH',
    none: 'none',
    fuzzy: 'FUZZY'
  };

  const fetchQuery = { type: typeOfData };

  let filtersForQuery = [];

  if (filterConfig) {
    const allFilterConfigValuesEmpty = Object.values(filterConfig).every(
      value => value === null || value === undefined
    );
    const allFilterConfigValuesSet = Object.values(filterConfig).every(
      value => value !== null && value !== undefined && value !== ''
    );
    if (allFilterConfigValuesSet) {
      filtersForQuery.push({
        field: filterConfig.filterBy,
        opKey: filterOperationAttrMap[filterConfig.filterOperation],
        value: filterConfig.filterValue
      });
    } else if (!allFilterConfigValuesEmpty) {
      const missingValues = Object.entries(filterConfig).filter(
        ([key, value]) => value === null || value === undefined || value === ''
      );
      const message = `The following filterConfig values are missing: ${missingValues
        .map(([key, value]) => key)
        .join(', ')}`;
      console.error(message);
      throw new Error(message);
    }
  }

  if (orgUnit) {
    filtersForQuery.push({
      field: 'organizations',
      opKey: 'CONTAINS',
      value: orgUnit
    });
  }

  if (filtersForQuery.length > 0) {
    fetchQuery.filters = filtersForQuery;
  }

  const authorizationToken = undefined;

  const url = accessSettings.ENDPOINTS.FIS_AC + 'list';

  const listData = await fetchJson(() => {
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorizationToken
      },
      body: JSON.stringify(fetchQuery)
    });
  });

  return listData;
};

export const getDatasetFromCKAN = async datasetId => {
  const url = `https://tucdata.informatik.tu-chemnitz.de/api/3/action/package_show?id=${encodeURI(datasetId)}`;
  const element = await fetchJson(() => {
    return fetch(url, {
      method: 'GET'
    });
  });

  //console.log(element);
  //console.log(mapDataciteToCSL(element.result));

  return element.result;
};

const getDatasetFromGroup = async groupId => {
  const url = `https://tucdata.informatik.tu-chemnitz.de/api/3/action/package_search?fq=organization:${encodeURI(groupId)}`;
  const element = await fetchJson(() => {
    return fetch(url, {
      method: 'GET'
    });
  });

  return element.result.results;
};

async function getPublicationById(id) {
  const sparql_query = `
          PREFIX dce: <http://purl.org/dc/elements/1.1/>
          PREFIX dct: <http://purl.org/dc/terms/>
          PREFIX bibo: <http://purl.org/ontology/bibo/>
          PREFIX tucbib: <https://tucbib.tu-chemnitz.de/schema/>

          SELECT DISTINCT ?type ?source ?publication ?doi ?publisher (GROUP_CONCAT(DISTINCT ?creator; SEPARATOR=";") as ?authors) (GROUP_CONCAT(DISTINCT ?possibleTitle; SEPARATOR="<!|!>") as ?titles) ?issn ?issued  ?available (GROUP_CONCAT(DISTINCT ?subject ; SEPARATOR=";") as ?keywords)  WHERE {
          ?publication dct:issued ?issued.
          ?publication tucbib:orderedAuthor ?creator.
          ?publication dce:publisher ?publisher.
          ?publication dce:type ?type.
          OPTIONAL{
            ?publication bibo:doi ?doi.
          }
          OPTIONAL{
            ?publication dct:available ?available.
          }
          OPTIONAL {
            ?publication dce:issn ?issn.
          }   
          ?publication dct:title ?possibleTitle.
          ?publication dct:isPartOf <https://boeken.informatik.tu-chemnitz.de/server/rdf/resource/tuc/${id}> .
          OPTIONAL
          {
              ?publication dce:subject ?subject.
          }
          }`;

  const dataToQuery = {
    format: 'application/sparql-results+json',
    query: sparql_query
  };

  const url =
    accessSettings.ENDPOINTS.TRIPLESTORE +
    'sparql' +
    '?' +
    new URLSearchParams(dataToQuery).toString();

  const publications = await fetchJson(() => {
    return fetch(url, {
      method: 'GET',
      mode: 'cors'
    });
  });

  //console.log(publications);

  return publications.results.bindings.map(publication =>
    mapPublicationToCSL(publication)
  );
}
