export const fetchJson = async fetchCallback => {
  try {
    const response = await fetchCallback();

    if (!response.ok) {
      if (response.status == 403)
        throw new Error('User not authorized for this action');
      if (response.status == 404)
        throw new Error('Requesteds Resource was not found');
      else throw new Error('Request Response Status was not in 200 range');
    }
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.error('Error in fetchJson:', error);
    throw error;
  }
};
