const attrToPropMap = {
  'given-name': 'firstName', // Contains string
  'family-name': 'lastName', // Contains string
  telephone: 'phone', // Contains string ?
  fax: 'fax', // Contains string ?
  email: 'primaryEmail', // Contains string
  'street-address': 'streetAddress', // Contains string
  locality: 'locality', // Contains string
  'postal-code': 'postalCode', // Contains string
  'room-old': 'locatedInRoomOld', // Contains array of strings
  'room-new': 'locatedInRoom', // Contains array of strings
  'research-areas': 'researchAreas', // Contains an array of objects with key 'label' as string
  // positions Contains an array of objects with keys 'label' and 'type' as string
  // and 'organizations' as array of objects with keys 'label' as string
  positions: 'positions'
};

const TucfisPeopleConfig = {
  tag: 'tucfis-people',
  allowedSortByVariablesAttrToProp: attrToPropMap,
  allowedFilterByVariablesAttrToProp: attrToPropMap
};

export default TucfisPeopleConfig;
