<script>
  import { onMount } from 'svelte';
  import ChevronDown from '../icons/ChevronDown.svelte';
  import ChevronUp from '../icons/ChevronUp.svelte';

  export let code;
  let codeElement;
  let isCollapsed = true;

  const highlight = () => {
    if (window.Prism) {
      Prism.highlightElement(codeElement);
    }
  };

  onMount(() => {
    if (window.Prism) {
      console.log('Prism available');
      highlight();
    } else {
      console.log('Prism not available');
    }
  });

  function toggleCollapse() {
    isCollapsed = !isCollapsed;
  }
</script>

<div class="code-container">
  <div class="code-header">
    <button on:click={toggleCollapse} class="toggle-button">
      <span class="toggle-icon">
        {#if isCollapsed}
          <ChevronDown />
        {:else}
          <ChevronUp />
        {/if}
      </span>
      <span class="toggle-text">{isCollapsed ? 'Show Code' : 'Hide Code'}</span>
    </button>
  </div>
  <div class="code-content" class:collapsed={isCollapsed}>
    <pre><code bind:this={codeElement} class="language-html">{code}</code></pre>
  </div>
</div>

<style lang="scss">
  .code-container {
    width: 100%;
    height: fit-content;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .code-header {
    background-color: #f5f5f5;
    padding: 0.5rem;
    border-bottom: 1px solid #ccc;
  }

  .toggle-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 7px;

    &:hover {
      text-decoration: underline;
    }

    /* span {
      margin-left: 0.5rem;
    } */
  }

  .toggle-icon {
    width: 20px;
    height: 20px;
    margin: 2px 0;
    color: #005f50;
  }

  .toggle-text {
    font-size: .9rem;
  }

  .code-content {
    transition: max-height 0.3s ease-in-out;
    max-height: 400px;
    overflow: hidden;

    &.collapsed {
      max-height: 0;
    }
  }

  pre {
    margin: 0;
    padding: 1rem;
    max-height: 400px;
    overflow-y: auto;
  }

  code {
    display: block;
    white-space: pre;
    overflow-x: auto;
    font-size: 0.9rem;
    line-height: 1.5;
  }
</style>
