<script>
  import { z } from 'zod';

  import TucfisServiceConfig from '../../config/web-components/TucfisServiceConfig.js';

  import { DataTransformer } from '../../utils/transformer.js';

  import { createParseAndValidateAttributesStore } from '../../utils/stores/parseAndValidateAttributesStore.js';

  import { getListData } from '../../services/accessControl.js';

  import Service from './Service.svelte';
  import ShowError from '../../components/ShowError.svelte';
  import Skeleton from '../shared/LoadingSkeletonSquare.svelte';
  import FilterBar from '../filterbar/FilterBar.svelte';

  export let orgUnit;
  export let sortBy;
  export let sort;
  export let limitTo;
  export let filterValue;
  export let filterBy;
  export let filterOperation;
  export let showFilterBar;

  const {
    attributes,
    state: attributesState,
    parseAndValidate: parseAttributes
  } = createParseAndValidateAttributesStore();

  $: parseAttributes({
    orgUnit: {
      variable: orgUnit,
      displayName: 'org-unit',
      validationSchema: z.string().min(1).optional()
    },
    sortBy: {
      variable: sortBy,
      displayName: 'sort-by',
      validationSchema: z
        .enum(Object.keys(TucfisServiceConfig.allowedSortByVariablesAttrToProp))
        .optional()
        .transform(
          value => TucfisServiceConfig.allowedSortByVariablesAttrToProp[value]
        )
    },
    filterBy: {
      variable: filterBy,
      displayName: 'filter-by',
      validationSchema: z
        .enum(
          Object.keys(TucfisServiceConfig.allowedFilterByVariablesAttrToProp)
        )
        .optional()
        .transform(
          value => TucfisServiceConfig.allowedFilterByVariablesAttrToProp[value]
        )
    }
  });

  let clientTransformer;

  $: {
    clientTransformer = new DataTransformer();

    switch ($attributes.sortBy) {
      default:
        clientTransformer = clientTransformer.sortString(sort, item => {
          return item[$attributes.sortBy];
        });
        break;
    }

    if (limitTo) {
      clientTransformer = clientTransformer.limitResults(limitTo);
    }
  }

  const updateSort = value => {
    sort = value;
  };

  const updateSortBy = value => {
    sortBy = value;
  };

  const updateLimitTo = value => {
    limitTo = value;
  };
</script>

{#if $attributesState.isValid}
  {#await getListData( 'services', { orgUnit, filterConfig: { filterBy: $attributes.filterBy, filterValue, filterOperation } } )}
    <div class="list-container">
      {#each new Array(5).fill(true) as _}
        <hr />
        <Skeleton />
      {/each}
    </div>
  {:then data}
    {#if showFilterBar}
      <FilterBar
        {updateSortBy}
        {updateSort}
        {sort}
        {sortBy}
        {limitTo}
        {updateLimitTo}
        showVariables={Object.keys(
          TucfisServiceConfig.allowedSortByVariablesAttrToProp
        )}
      />
    {/if}
    <div class="list-container">
      {#each clientTransformer.transform(data) as service}
        <hr />
        <Service
          id={service.id}
          title={service.name}
          description={service.description}
          manager={service.manager}
          image={service.iconSrc}
        />
      {/each}
    </div>
  {:catch e}
    <ShowError message={'Error when loading data: ' + e.toString()} />
  {/await}
{:else}
  <ShowError message={$attributesState.errors} />
{/if}

<style lang="scss">
  .list-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    padding: 14px 0;
  }

  .list-container hr:first-child {
    display: none;
  }
</style>
