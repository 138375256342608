<script>
  import { getContext } from 'svelte';
  import i18n_dictionary from '../../../config/i18n.js';
  import Cite from '../../icons/Cite.svelte';

  export let onClick;

  const i18n = key => {
    return i18n_dictionary[key][getContext('language')];
  };
</script>

<button on:click={() => onClick()}>
  <span>
    <Cite />
  </span>
  <span>{i18n('cite')}</span>
</button>

<style lang="scss">
  button {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-right: 30px;
    font-size: calc(var(--font-size) * 1.1);
    color: var(--primary-color);
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    border: none;
  }

  button:hover {
    text-decoration: underline;
  }

  button :last-child {
    margin-left: 5px;
    font-style: italic;
    font-weight: bolder;
  }

  button :first-child {
    width: calc(var(--font-size) * 1.2);
    //margin: 0 8px;
    color: var(--primary-color);
  }
</style>
