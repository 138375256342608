<script>
  import { getContext } from 'svelte';
  import i18n_dictionary from '../../../config/i18n.js';

  export let data;
  export let copied = false;

  const copyDataToClipboard = data => {
    navigator.clipboard.writeText(data).then(
      () => {
        copied = true;
        setTimeout(() => {
          copied = false;
        }, 1500);
      },
      () => {
        copied = false;
      }
    );
  };

  const i18n = key => {
    return i18n_dictionary[key][getContext('language')];
  };
</script>

<button
  title="Copy citator"
  on:click={() => {
    copyDataToClipboard(data);
  }}
  >{#if copied}
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 12"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M1 5.917 5.724 10.5 15 1.5"
      />
    </svg>
  {:else}
    <svg
      class=""
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 20"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m7.708 2.292.706-.706A2 2 0 0 1 9.828 1h6.239A.97.97 0 0 1 17 2v12a.97.97 0 0 1-.933 1H15M6 5v4a1 1 0 0 1-1 1H1m11-4v12a.97.97 0 0 1-.933 1H1.933A.97.97 0 0 1 1 18V9.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 5h5.239A.97.97 0 0 1 12 6Z"
      />
    </svg>
  {/if}
</button>

<style lang="scss">
  button {
    cursor: pointer;
    background-color: transparent;
    padding: 2px;
    margin: 0;
    border: none;
    color: white;
  }

  button:hover {
    background-color: lightgray;
  }
  
  button svg {
    width: 1.4em;
    height: 1.4em;
    color: #000;
  }
</style>
