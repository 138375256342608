<script>
  import { createEventDispatcher } from 'svelte';

  export let logosrc;
  export let currentPage;
  let isMenuOpen = false;

  const dispatch = createEventDispatcher();

  const navLinks = [
    { href: '#persons', title: 'Persons', text: 'Persons', page: 'person' },
    { href: '#projects', title: 'Projects', text: 'Projects', page: 'project' },
    { href: '#services', title: 'Services', text: 'Services', page: 'service' },
    {
      href: '#publications',
      title: 'Publications',
      text: 'Publications',
      page: 'publication'
    },
    { href: '#events', title: 'Events', text: 'Events', page: 'event' }
  ];

  function toggleMenu() {
    isMenuOpen = !isMenuOpen;
  }

  function handleNavClick(page) {
    dispatch('pageChange', { page });
    isMenuOpen = false;
  }
</script>

<header id="branding">
  <nav class="navbar-container">
    <a
      title="TUC Forschungsinformationssystem - Web Components"
      href="index.html"
      class="logo-container"
    >
      <span class="sr-only">
        TUC Forschungsinformationssystem - Web Components
      </span>
      <img src={logosrc} alt="Logo der TU-Chemnitz" />
    </a>
    <div class="project-name-container-mobile">
      <span class="project-name">TUCfis</span>
      <span class="alpha-label"> alpha </span>
    </div>
    <button class="menu-toggle" on:click={toggleMenu} aria-label="Toggle menu">
      <span class="bar"></span>
      <span class="bar"></span>
      <span class="bar"></span>
    </button>
    <hr class="vertical-line" />
    <div class="nav-links-container" class:open={isMenuOpen}>
      <div class="nav-links">
        {#each navLinks as link, index}
          {#if index > 0}
            <hr class="vertical-line" />
          {/if}
          <a
            href={link.href}
            title={link.title}
            class:active={currentPage === link.page}
            on:click|preventDefault={() => handleNavClick(link.page)}
          >{link.text}</a>
        {/each}
      </div>
      <div class="project-name-container-desktop">
        <span class="project-name">TUCfis</span>
        <span class="alpha-label"> alpha </span>
      </div>
    </div>
  </nav>
</header>

<style lang="scss">
  #branding {
    display: flex;
    justify-content: center;
    /* align-items: stretch; */
    background-color: #005f50;
  }

  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 100px;
  }

  .logo-container {
    margin: 10px 0;
  }

  .logo-container img {
    height: 100%;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .vertical-line {
    width: 1px;
    height: 80%;
    background-color: #fff;
    margin: auto 30px;
  }

  .nav-links-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 8px 0;
  }

  .nav-links {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-grow: 1;
    margin-bottom: 2px;
  }

  .nav-links a {
    color: #ddd;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.1);
    }

    &.active {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.2);
      /* font-weight: bold; */
    }
  }

  .nav-links hr {
    margin: 0 20px;
    height: 60%;
  }

  .project-name-container-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    column-gap: 4px;
  }

  .project-name-container-mobile {
    display: none;
  }

  .project-name {
    font-size: 1.5em;
    color: #fff;
  }

  .alpha-label {
    background-color: #fff;
    color: #005f50;
    font-size: 0.7em;
    padding: 1px 3px;
    margin-top: 3px;
    border-radius: 2px;
    align-self: flex-start;
  }

  .menu-toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
  }

  .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    background-color: #fff;
  }

  @media (max-width: 768px) {
    #branding {
      justify-content: stretch;
    }
    .navbar-container {
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
      height: auto;
      padding: 10px 30px;
    }

    .logo-container {
      margin: 10px 0;
    }

    .logo-container img {
      height: 60px;
    }

    .menu-toggle {
      display: block;
      height: fit-content;
      margin: auto 0;
      margin-left: 20px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
      border-radius: 10%;
    }

    .nav-links-container {
      flex-basis: 100%;
      display: none;
      margin-top: 10px;

      &.open {
        display: flex;
      }
    }

    .nav-links {
      flex-direction: column;
      align-items: flex-start;
    }

    .nav-links a {
      padding: 10px 0;
    }

    .vertical-line {
      display: none;
    }

    .project-name-container-desktop {
      display: none;
    }

    .project-name-container-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
    }

    .alpha-label {
      align-self: center;
      margin-bottom: 20px;
      margin-left: 6px;
    }
  }
</style>