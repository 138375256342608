<script>
  import { getContext } from 'svelte';
  import i18n_dictionary from '../../config/i18n.js';
  import Description from '../shared/Description.svelte';
  import File from '../icons/File.svelte';
  import Cite from '../icons/Cite.svelte';
  import { mapDataciteToCSL } from '../../utils/parsers.js';
  import CiteButton from '../shared/citation/CiteButton.svelte';

  export let title;
  export let authors;
  export let publisher;
  export let publicationYear;
  export let resourceType;
  export let license;
  export let description;
  export let tags;
  export let resources;
  export let showCitationDialog;
  export let rawData;

  const i18n = key => {
    return i18n_dictionary[key][getContext('language')];
  };
</script>

<div id="main-container">
  <div id="title-container">
    <h3 id="title">{title}</h3>
    <CiteButton onClick={() => showCitationDialog(mapDataciteToCSL(rawData))} />
  </div>
  <p class="date">
    {new Date(publicationYear).toLocaleDateString(getContext('language'), {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })}
  </p>

  <div id="data-rows">
    <Description {description} />
    <div id="resources">
      <b>{i18n('data')}: </b>
      {#each resources as resource}
        <a href={resource.url} target="_blank" rel="noopener noreferrer">
          <File style={'width: 25px;'} />
          {resource.name}
        </a>
      {/each}
    </div>

    <p>
      <b>{i18n('dataType')}: </b>{resourceType}
    </p>
    <p>
      <b>{i18n('dataLicense')}: </b><a href={license.url}>{license.title}</a>
    </p>
    <p id="authors">
      <b>{i18n('authors')}: </b>
      {authors.join(', ')}
    </p>
    <p>
      <b>{i18n('publisher')}: </b>{publisher}
    </p>
    <p>
      <b>{i18n('tags')}: </b>{tags.map(tag => tag.display_name).join(', ')}
    </p>
  </div>
</div>

<style lang="scss">
  #main-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    row-gap: 15px;
    //background-color: red;
  }

  #main-container > * {
    margin: 0;
    padding: 0;
  }

  #title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  #title {
    margin: 0;
    padding: 0;
    color: var(--primary-color);
    font-size: calc(var(--font-size) * 1.1);
  }

  .date {
    font-weight: bold;
    margin: 0;
  }

  #data-rows {
    display: flex;
    flex-direction: column;
    gap: 11px;
  }

  #data-rows p {
    margin: 0;
    padding: 0;
  }

  #authors {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  #resources {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  #resources a {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: end;
  }

  #data-rows a,
  #resources a {
    text-decoration: none;
    color: var(--primary-color);
  }

  #data-rows a:hover,
  #resources a:hover {
    text-decoration: underline;
  }
</style>
