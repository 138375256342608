<svelte:options
  customElement={{
    tag: 'tucfis-list',
    props: {
      sortBy: { attribute: 'sort-by' },
      limitTo: { attribute: 'limit-to', type: 'Number' },
      showFilterBar: { attribute: 'show-filter-bar', type: 'Boolean' },
      hideStudentPhoneFax: {
        attribute: 'hide-student-phone-fax',
        type: 'Boolean'
      },
      dontShowList: { attribute: 'dont-show', type: 'Array' },
      orgUnit: { attribute: 'org-unit' },
      filterValue: { attribute: 'filter-value' },
      filterBy: { attribute: 'filter-by' },
      filterOperation: { attribute: 'filter-operation' },
      urls: { attribute: 'urls', type: 'Object' }
    }
  }}
/>

<script>
  import { setContext } from 'svelte';
  import { z } from 'zod';

  import { createParseAndValidateAttributesStore } from '../utils/stores/parseAndValidateAttributesStore.js';

  import ProjectList from '../components/project/ProjectList.svelte';
  import ServiceList from '../components/service/ServiceList.svelte';
  import PeopleList from '../components/people/PeopleList.svelte';
  import ShowError from '../components/ShowError.svelte';
  import PublicationList from '../components/publication/PublicationList.svelte';
  import EventList from '../components/event/EventList.svelte';
  import DatasetList from '../components/dataset/DatasetList.svelte';

  export let entity; // Type of component to display
  export let lang = 'en';
  export let orgUnit = undefined; // ID of the organizational unit
  export let context = undefined;
  export let sortBy = undefined;
  export let sort;
  export let limitTo = undefined;
  export let filterValue = undefined;
  export let filterBy = undefined;
  export let filterOperation = undefined;
  export let showFilterBar = false;
  export let hideStudentPhoneFax = false;
  export let dontShowList = [];
  export let urls = undefined;

  //$: console.log('urls', urls);

  setContext('language', lang);

  const {
    state: attributesState,
    parseAndValidate: parseAttributes,
    attributes
  } = createParseAndValidateAttributesStore();

  // Parse attributes that are general to all components
  $: parseAttributes({
    entity: {
      variable: entity,
      validationSchema: z.enum([
        'services',
        'people',
        'projects',
        'publications',
        'datasets',
        'events'
      ])
    },
    lang: {
      variable: lang,
      validationSchema: z.enum(['en', 'de'])
    },
    sort: {
      variable: sort,
      validationSchema: z.enum(['ascending', 'descending']).optional()
    },
    limitTo: {
      variable: limitTo,
      displayName: 'limit-to',
      validationSchema: z.number().int().positive().optional()
    },
    filterValue: {
      variable: filterValue,
      displayName: 'filter-value',
      validationSchema: z.string().optional()
    },
    filterOperation: {
      variable: filterOperation,
      displayName: 'filter-operation',
      validationSchema: z
        .enum([
          'equals',
          'includes',
          'starts-with',
          'ends-with',
          'none',
          'fuzzy'
        ])
        .optional()
    }
  });
</script>

{#if $attributesState.isValid}
  {#if entity === 'projects'}
    <ProjectList
      {orgUnit}
      {sortBy}
      {sort}
      {limitTo}
      {filterValue}
      {filterBy}
      {filterOperation}
      {showFilterBar}
    />
  {:else if entity === 'services'}
    <ServiceList
      {orgUnit}
      {sortBy}
      {sort}
      {limitTo}
      {filterValue}
      {filterBy}
      {filterOperation}
      {showFilterBar}
    />
  {:else if entity === 'people'}
    <PeopleList
      {orgUnit}
      {context}
      {sortBy}
      {sort}
      {limitTo}
      {filterValue}
      {filterBy}
      {filterOperation}
      {showFilterBar}
      {hideStudentPhoneFax}
      {dontShowList}
      {urls}
    />
  {:else if entity === 'publications'}
    <PublicationList {orgUnit} {sortBy} {sort} {limitTo} {showFilterBar} />
  {:else if entity == 'events'}
    <EventList {sortBy} {sort} {limitTo} {showFilterBar} />
  {:else if entity == 'datasets'}
    <DatasetList {orgUnit} {sortBy} {sort} {limitTo} {showFilterBar} />
  {/if}
{:else}
  <ShowError message={$attributesState.errors} />
{/if}
