<script>
  import { z } from 'zod';
  import ShowError from '../ShowError.svelte';
  import { getListData } from '../../services/accessControl';
  import { DataTransformer } from '../../utils/transformer.js';
  import { createParseAndValidateAttributesStore } from '../../utils/stores/parseAndValidateAttributesStore.js';
  import LoadingSkeleton from '../shared/LoadingSkeletonSquare.svelte';
  import FilterBar from '../filterbar/FilterBar.svelte';
  import Event from './Event.svelte';
  import TucfisEventConfig from '../../config/web-components/TucfisEventConfig.js';

  export let sort = 'descending';
  export let sortBy = 'date';
  export let limitTo = 100;
  export let showFilterBar;

  const {
    attributes,
    state: attributesState,
    parseAndValidate: parseAttributes
  } = createParseAndValidateAttributesStore();

  $: parseAttributes({
    sortBy: {
      variable: sortBy,
      displayName: 'sort-by',
      validationSchema: z
        .enum(Object.keys(TucfisEventConfig.allowedSortByVariablesAttrToProp))
        .optional()
        .transform(
          value => TucfisEventConfig.allowedSortByVariablesAttrToProp[value]
        )
    }
  });

  let clientTransformer;

  $: {
    clientTransformer = new DataTransformer();

    switch ($attributes.sortBy) {
      case 'date':
        clientTransformer = clientTransformer.sortDate(
          sort,
          item => item.intervalStart
        );
        break;
      default:
        clientTransformer = clientTransformer.sortString(sort, item => {
          return item[$attributes.sortBy];
        });
        break;
    }

    if (limitTo) {
      clientTransformer = clientTransformer.limitResults(limitTo);
    }
  }

  const updateSort = value => {
    sort = value;
  };

  const updateSortBy = value => {
    sortBy = value;
  };

  const updateLimitTo = value => {
    limitTo = value;
  };
</script>

{#if $attributesState.isValid}
  {#await getListData('events')}
    <div class="list-container">
      {#each Array(5).fill() as _}
        <LoadingSkeleton />
      {/each}
    </div>
  {:then data}
    {#if showFilterBar}
      <FilterBar
        {limitTo}
        {updateLimitTo}
        {sort}
        {sortBy}
        {updateSort}
        {updateSortBy}
        showVariables={Object.keys(
          TucfisEventConfig.allowedSortByVariablesAttrToProp
        )}
      />
    {/if}
    <div class="list-container">
      {#each clientTransformer.transform(data) as event}
        <Event
          uri={event.id}
          title={event.name}
          image={event.image}
          startDate={event.intervalStart}
          endDate={event.intervalEnd}
          description={event.description}
          speaker={event.speaker}
          info={event.info}
        />
        <hr />
      {/each}
    </div>
  {:catch e}
    <ShowError message={'Error when loading data: ' + e.toString()} />
  {/await}
{:else}
  <ShowError message={$attributesState.errors} />
{/if}

<style lang="scss">
  .list-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    padding: 14px 0;
  }
  .list-container hr:last-child {
    display: none;
  }
</style>
