<script>
  import { z } from 'zod';

  import TucfisDatasetConfig from '../../config/web-components/TucfisDatasetConfig.js';

  import { DataTransformer } from '../../utils/transformer.js';

  import { createParseAndValidateAttributesStore } from '../../utils/stores/parseAndValidateAttributesStore.js';

  import Dataset from './Dataset.svelte';
  import ShowError from '../ShowError.svelte';
  import Skeleton from '../shared/LoadingSkeletonSquare.svelte';
  import CitationDialog from '../shared/citation/CitationDialog.svelte';
  import { getListData } from '../../services/accessControl.js';
  import FilterBar from '../filterbar/FilterBar.svelte';

  export let orgUnit;
  export let sortBy;
  export let sort;
  export let limitTo;
  export let showFilterBar;

  const {
    attributes,
    state: validationState,
    parseAndValidate
  } = createParseAndValidateAttributesStore();

  $: parseAndValidate({
    orgUnit: {
      variable: orgUnit,
      displayName: 'org-unit',
      validationSchema: z.string().min(1)
    },
    sortBy: {
      variable: sortBy,
      displayName: 'sort-by',
      validationSchema: z
        .enum(Object.keys(TucfisDatasetConfig.allowedSortByVariablesAttrToProp))
        .optional()
        .transform(value => {
          return TucfisDatasetConfig.allowedSortByVariablesAttrToProp[value];
        })
    }
  });

  let clientTransformer;

  $: {
    clientTransformer = new DataTransformer();

    switch ($attributes.sortBy) {
      default:
        clientTransformer = clientTransformer.sortString(sort, item => {
          return item[$attributes.sortBy];
        });
        break;
    }

    if (limitTo) {
      clientTransformer = clientTransformer.limitResults(limitTo);
    }
  }

  let citationDialog;
  const showCitationDialog = data => citationDialog.showCitation(data);

  const updateSort = value => {
    sort = value;
  };

  const updateSortBy = value => {
    sortBy = value;
  };

  const updateLimitTo = value => {
    limitTo = value;
  };
</script>

{#if $validationState.isValid}
  {#await getListData('datasets', { orgUnit: $attributes.orgUnit })}
    <div class="list-container">
      {#each new Array(5).fill(true) as _}
        <hr />
        <Skeleton />
      {/each}
    </div>
  {:then data}
    <CitationDialog bind:this={citationDialog} />
    {#if showFilterBar}
      <FilterBar
        {limitTo}
        {updateLimitTo}
        {sort}
        {sortBy}
        {updateSort}
        {updateSortBy}
        showVariables={Object.keys(
          TucfisDatasetConfig.allowedSortByVariablesAttrToProp
        )}
      />
    {/if}
    <div class="list-container">
      {#each clientTransformer.transform(data) as dataset}
        <hr />
        <Dataset
          title={dataset.title}
          authors={dataset.creators}
          publisher={dataset.publisher}
          publicationYear={dataset.publication_year}
          resourceType={dataset.resource_type}
          description={dataset.description}
          tags={dataset.tags}
          license={{
            title: dataset.license_title,
            url: dataset.license_url
          }}
          resources={dataset.resources}
          {showCitationDialog}
          rawData={dataset}
        />
      {/each}
    </div>
  {:catch e}
    <ShowError message={'Error when loading data: ' + e.toString()} />
  {/await}
{:else}
  <ShowError message={$validationState.errors} />
{/if}

<style lang="scss">
  .list-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    padding: 14px 0;
  }

  .list-container hr:first-child {
    display: none;
  }
</style>
