<script>
  //export let language = "de";
  import { getContext, onMount } from 'svelte';
  import i18n_dictionary from '../../../config/i18n.js';
  import { initiateCitation } from '../../../utils/citation.js';
  import CopyData from './CopyData.svelte';
  //import { Cite } from '@citation-js/core';

  let citationDialog;

  let data;

  export function showCitation(newData) {
    data = newData;
    console.log(data);
    citationDialog.showModal();
  }

  let citator;

  let formattingStyle = {
    structure: 'bibliography',
    format: 'text'
  };

  let citations = {
    ieee: {
      name: 'ieee',
      label: 'IEEE'
    },
    apa: {
      name: 'apa',
      label: 'APA'
    }
  };

  $: {
    if (citator && data) {
      Object.keys(citations).forEach(citation => {
        citations[citation].value = citator?.cite(data, {
          ...formattingStyle,
          template: citation
        });
      });
    }
  }

  const i18n = key => {
    return i18n_dictionary[key][getContext('language')];
  };

  onMount(async () => {
    citator = await initiateCitation();
  });
</script>

<dialog class="tucbib-cite-dialog" bind:this={citationDialog}>
  <article>
    <header>
      <button aria-label="Close" on:click={() => citationDialog.close()}>
        X
      </button>
      <h4>{i18n('cite')}</h4>
    </header>
    <body>
      <div class="tucbib-cite-dialog-format">
        <select
          aria-label="Citation format"
          name="Format"
          on:change={e => (formattingStyle.format = e.target.value)}
        >
          <option value="html">HTML</option>
          <option value="text" selected>{i18n('rawText')}</option>
        </select>
      </div>
      <hr />
      <table class="tucbib-cite-dialog-citations">
        {#each Object.values(citations) as citationStyle}
          {#if citationStyle.value?.length}
            <tr>
              <th>
                <label for={citationStyle.name}>
                  <b>{citationStyle.label}</b>
                </label>
              </th>
              <td>
                <p id={citationStyle.name}>
                  {citationStyle.value?.length
                    ? citationStyle.value
                    : i18n('noCitation')}
                </p>
              </td>
              <td>
                <CopyData data={citationStyle.value} />
              </td>
            </tr>
          {:else}
            <tr>
              <th>
                <label for={citationStyle.name}>
                  <b>{citationStyle.label}</b>
                </label>
              </th>
              <td>
                <p id={citationStyle.name}>
                  {i18n('noCitation')}
                </p>
              </td>
            </tr>
          {/if}
        {/each}
      </table>
    </body>
  </article>
</dialog>

<style lang="scss">
  .tucbib-cite-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.8);
  }

  .tucbib-cite-dialog::backdrop {
    background-color: #000;
    opacity: 0.6;
  }

  .tucbib-cite-dialog header {
    background-color: #6f7070;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 8px;
  }

  .tucbib-cite-dialog header h4 {
    margin: auto;
    font-size: calc(var(--font-size) * 1.2);
    color: white;
  }

  .tucbib-cite-dialog button {
    cursor: pointer;
    background-color: transparent;
    padding: 1px 4px;
    margin: 0;
    border: none;
    color: white;
  }

  .tucbib-cite-dialog button:focus,
  .tucbib-cite-dialog button:active {
    outline: none;
    border: 2px solid white;
  }

  .tucbib-cite-dialog button:hover {
    background-color: lightgray;
  }

  .tucbib-cite-dialog-format select {
    margin: 4px 3px;
    padding: 6px 8px;
    border: 1px solid var(--tucal-color-nw);
    background-color: transparent;
    color: inherit;
  }

  .tucbib-cite-dialog-citations {
    border-spacing: 10px; /* Adjust as needed */
  }

  .tucbib-cite-dialog-citations td,
  .tucbib-cite-dialog-citations th {
    padding: 10px; /* Adjust as needed */
    vertical-align: top;
  }

  .tucbib-cite-dialog-citations p {
    margin: 0;
  }
</style>
